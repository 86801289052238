import React from 'react';
import {Button,Modal,} from 'antd';
import Markdown from 'react-remarkable'

class AgreementModal extends React.Component {
    static defaultProps={
        data: `
### POWERFACTOR SUBSCRIPTION AGREEMENT
##### THE PARTIES AND DEFINITIONS 

This Subscription Agreement (Agreement) is signed by and between Architecht Bilişim Sistemleri ve Pazarlama Ticaret A.Ş (hereinafter referred to as Provider) and the user (hereinafter referred to as Customer) who signed up to the website www.pwfactor.com  

The Provider and the Customer shall be reffered to collectively as the Parties, individiually as the Party. 

The Agreement is signed for allowing the Customer to use PowerFactor product of the Provider in pay as you go model. 

##### DEFINITIONS 

Account: It referres to the account that Customer uses to access the Platform. To reach the Account, Customer will receive a password through their email adress. 

Package: It refers to the service package that determines Customer’s usage of the Platform. Service Fee may change depending on the selected Package. Provider has the right to change the scope of the packages. 

Platform: It refers to the SaaS PowerFactor product provided by Provider to Customer through the Public Cloud.  

Public Cloud: Cloud infrastructure available for open use by the general public. 

Service Fee: It referres to the fees that the Customer must pay to benefit from the Platform or other services herein. Service Fee shall be announced on the Website or notified to Customer during the negotiations with them. 

Trial: Trial period starts after the Agreement becomes effective and continues for the period spesified on the Website. During this period, the Customer can access the Platform free of charge within the limitations set by Provider. 

Website: It refers to the website www.pwfactor.com 

##### SUBJECT 

This Agreement is concluded for the purpose of determining the scope, terms, pricing and other related points of Customer’s usage of PowerFactor and also the rights and obligations of the Parties. 

Provider shall provide the services of multi factor authentication through the Platform within the scope of this Agreement. Customer shall benefit from the Platform with a software as a service approach. 

##### LICENCE 

PowerFactor is developed by Provider and works in Software as a Service (SaaS) model on Public Cloud. Customer shall use the Platform in ‘pay as you go’ model. This Agreement does not give any right to Customer to own the license of PowerFactor since PowerFactor is a SaaS product and licensed in ‘pay as you go’ model. 

##### PROVIDER’S RIGHTS and OBLIGATIONS 

Provider accepts to provide the services specified in the Agreement within the scope of the Package chosen by Customer, unless the Agreement ends or Customer acts contrary to the Agreement. 

Customer can use the Platform within the limit stated on the Website during Trial. 

Customer can benefit from the Trial for only once. Trial lasts as specified on the Website. Provider has the right to end the trial earlier than stated on the Website without the need of a prior notification. 

The content created by Customer through the Platform might be deleted after the Trial is over or the Agreement is terminated in any way. Provider is not responsible for any data loss. The backup and storage of the data are under Customer’s sole responsibility. 

Provider has the right to maintain, update and change the Platform in any way. Provider has the right to  add new features to the Platform or remove features from it. 

Provider has the right to use the name/title of Customer on the Website as reference. Provider shall inform Customer about the usage of their name/title.  

Provider provides the services herein on an “as is” basis. Provider does not undertake anything regardind the operation of the Platform or its suitability for any purpose. Customer uses the Provider at its own risk.  

Provider has the right to change the Agreement and its annexes without a prior notification. If Customer keeps using the Platform or other services provided by Provider, that means Customer accepts the changes. If Customer does not accept the changes, they can terminate the Agreement. Customer who terminates the Agreement cannot use the Platform or reach the content created with the Platform. 

Customer who terminates the Agreement shall not have the right to get refund of the paid Service Fee and shall pay the remaining installments (if any). 

Provider holds the right to share Customer's information with its affiliates, the parent company it is affiliated with, and the third parties it receives service when it deems necessary. Provider informs the third parties about the precautions to be taken when storing Customer’s information. 

Provider has the right to access Customer’s data to carry out maintenance or fix technical problems related to the Platform’s software or source code. 

Provider does not store any data of Customer openly. 

Provider does not openly transfer Customer information for intersystem communication methods. Only encrypted data shall be transfered in all communications. 

Customer cannot hold Provider responsible for any claim made by third parties arising from the use or disclosure of its data. 

##### CUSTOMER’S RIGHTS and OBLIGATIONS 

Customer accepts to use the Platform through their Account. Customer is responsible for the privacy and security of the Account’s password. Customer shall not share the password with irrelevant people and instutions and shall inform Provider immediately after noticing any security violation, show maximum effort to stop the violation and collaborate with Provider.  

Customer is solely responsible for the damage arising from the password’s usage by unauthorized people.  

Customer shall inform Provider about the people who has access to the Platform and does not have the authority to act on behalf of Customer anymore. Customer shall prevent unauthorized people to access the passwords to use the Platform and change these passwords if necessary.    

Customer accepts and declares that they have the legal capacity to sign the Agreement, fulfill its obligations and use the Platform; furthermore if they access to the Platform in the name of a company they have the full authority for it.  

Customer accepts to collaborate with Provider on fulfilling Provider‘s obligations arising from the Agreement.  

Any legal, criminal, administrative responsibility arising from usage of the Platform belongs solely to Customer. 

Customer does not have the right to copy, change, produce something similar to, expose, spread, distribute or split any part of the Platform. The Platform shall not be partially or completely deciphered, disassembled, converted into source code or reverse engineered by the Customer in any case. 

Customer shall not remove or make invisible any PowerFactor or Architecht mark, sign, logo, brand, registry on the Platform or any  product subject to this Agreement.  

Customer is solely responsible for the backup and storage of any data or documents uploaded to the Public Cloud or the Platform. Provider does not guarantee that there shall be no content loss on the Public Cloud or the Platform. Therefore Provider cannot be held responsible for any data loss. Provider reserves the right to limit the Customer’s data storage space on the Public Cloud or Platform. 

Customer shall not connect the Platform to any software or system without Provider’s written consent.  

Customer shall not give services to the third parties by using the Platform or open the Platform for a third party to use. 

Customer agrees and accepts in advance that the usage of the Platform may be restricted for various reasons. 

Customer is obligated to protect any information that may be used to access the Platform and prevent this information’s use by unauthorized people. 

 

##### THE LIMITATION of LIABILITIES 

Customer uses the Platform and the services herein at its own risk and subject to the following disclaimers: Provider is providing its business services on an “as is” basis without any express or implied warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, title, non-infringement, and freedom from computer virus or other harmful code. Provider does not warrant that any information provided by Provider is accurate, complete, or useful; that the api and business services or any other services will be operational, error free, secure, or safe; or that the Platform and business services or any other services will function without disruptions, delays, or imperfections. Provider does not control, and is not responsible for controlling, how or when its users use the Platform, its business services or other services, or the features, functionalities, and interfaces its business services or other services provide. Provider is not responsible for and is not obligated to control the actions or information (including content) of its users or other third parties. This section is without prejudice to its obligations as a data processor under the terms. 

Provider will not be liable to Customer for any lost profits or consequential, special, punitive, indirect, or incidental damages relating to, arising out of, or in any way in connection with these business terms, Provider, or its business services, even if Provider has been advised of the possibility of such damages. Provider’s aggregate liability relating to, arising out of, or in any way in connection with these business terms, or its business services will not exceed the greater of the amount of  Service Fee. The foregoing disclaimer of certain damages and limitation of liability will apply to the maximum extent permitted by applicable law.  

Customer accepts that Provider is not responsible for any intellectual property violation related to the content made with or reached through the Platform and any allegation or demand related to the usage of the Platform that can be transmitted by third parties.  

Provider does not warrant the compatibility of the Platform with any device or internet browser.  Customer agrees and declares in advance that Provider cannot be held responsible for the service of the internet service provider, the quality of it, and any malfunctions that may occur in the use of the Platform because of it. 

Provider shall not be responsible for the deletion of the data uploaded to the Platform or Public Cloud due to the legislation and any damage that may occur as a consequence. 

 

##### PROVISIONS ON USAGE OF THE PLATFORM 

Customer is obliged to provide the necessary computer hardware, internet connection, telephone connection and other technical requirements at its own expense in order to be able to operate with the Platform accordingly to the Agreement. 

Customer is responsible and obligated to ensure the safety of their computer, the location where the computer is and their security information, to take precautions to prevent the third parties’ access to the information or the system illegally. Provider has no responsibility for physical or online interfrence and attacks to the location or to the computer where the Platform is used on. Therefore all kinds of damage, loss and harm including unauthorized people taking action on the Platform are solely Customer’s responsibility.  

Customer understands and accepts that from time to time, entry and use of the Platform may be stopped by Provider for technical, administrative, legal or other reasons without any prior notice or warning or may be stopped entirely. Customer irrevocably accepts, declares and undertakes that they shall not claim any compensation, expense, right and receivabe due to their access to the Platform is stopped entirely or partly, temporarly or permanantly by Provider for any reason, they shall not refrain from paying their debts to Provider, they waives in advance all rights and receivables in this matter. 

 

##### PROVISIONS on INTELLECTUAL PROPERTY RIGHTS 

Through this Agreement, Customer shall not gain any right, allegation or advantage on Provider’s intellectual property rights. Customer accepst and undertakes all kinds of intellectual property rights, moral rights, all kinds patent, trademark, copyright on the Platform and all related programs, software, source codes, object codes, machine codes, all other codes, algorithms, interfaces, components, modules shall be belong to Provider. In other words, the Customer does not have any intellectual property right on the Platform. 

Customer has only the right of use the services subject the Agreement and there shall be no licence tranfer to the Customer for the Platform or other services herein. Customer shall not claim to have any right other that the right of use and shall not transfer the right of use to the third parties.  

Customer shall use Provider’s title, brand, logo and signs only with a written consent and approval from Provider and within the notified limits. Customer does not have a property right or any other rights on Provider’s title, brand, logo or signs (given implicitly or by another way). 

All of the intellectual property rights related to the services subject to the Agreement, the Platform, relevant documents, the commercial reputation that comes with them belong only to Provider. Customer cannot lay claim to them.  Customer irrevocably declares to never have a claim for these rights and ensure that their employees, agents and contractors shall act the same way.   

##### PRIVACY 

Parties accept that the information belonging to the other Party, its affiliates, customers and employees that given for the making and practicing of this Agreement is confidential information. The Parties accept, declare and undertake that: 

They shall use the confidential information for the purpose of fulfilling the obligations under this Agreement only, take pracautions for protecting and storing the other Party’s information as if they take care of their own information. 

They shall not share the other Party’s confidential information with third parties or make it public without a written consent from the other Party.  

They shall not use the confidential information for a purpose that is not related to the commercial relation between them directly or indirectly. 

They shall not use the confidental information with the purpose of harming the other Party directly or indirectly, causing a commercial disadvantage to the other Party or use it against the rules of competition law or applicable law. 

Their employees and representatives shall keep the confidential information private and they shall not reveal it partly or entirely to anyone other than the employees and represantative who need the information for work. 

They shall return the confidential information to the Party that owns the information upon a request or after the Agreement terminated, if cannot be returned, destroy the information in a way that the other Party approves except when the legislation requires keeping them. 

In case it is required to reveal any of the confidential information as required by the legislation, they shall inform the other Party about this request or order and enable the other Party to seek a way of protection against such request. 

When they detect that the confidential information is being used unauthoried or exposed, they shall inform the other Party immediately and collaborate with the other Party to fix it/ensure it shall not happen again.  

Except it is legally obliged or allowed in the Agreement, if a Party expose confidential information, the Party whose confidential information is exposed shall have the right to terminate the Agreement ex parte. Therefore the Parties shall not demand any rights, receivables, compensation or other payment. 
 

##### FINAL PROVISIONS 

a) The Term of the Agreement  

The Agreement enters into force when Customer signed up to the Platform.  

Provider has the right to terminate the Agreement unilaterally by notifying Customer 5 days in advance. 

Provider has the right to terminate the Agreement and the services unilaterally and without a prior notice if Customer violates its obligations arising from this Agreement or the law. In that case, Customer can not get a refund on the Service Fees previously paid or if not paid yet Customer has to pay the full amount of th Service Fee immediately. It is the Customer's responsibility to compensate Provider's damages due to such violation. 

b) The Fees and Payment 

Provider shall inform Customer about the Service Fee by announcing on the Website. Customer who wants to benefit from the Platform and/or any service offered under this Agreement is obliged to pay the Service Fee as specified on the Website. Customer is also solely responsible for any taxes payable for the services under the Agreement. Provider reserves the right to change the Service Fee or form of payment at any time. 

Service Fee shall be calculated annually and billed to Customer. Customer shall pay the calculated Service Fee to Provider in 5 days after the bill has received. 

c) Force Majeure 

Political reasons such as war, uprising, state of emergency, declaration of mobilization, natural disasters such as fire, flood, earthquake, deficiencies in the electrical system or internet traffic, official actions affecting online services, situations that occur outside Provider’s will or control affecting the services subject to this Agreement are called force majeure. Provider cannot be held responsible for any performance degradation in these cases. In case of force majeure lasts more than 15 (fifteen) days, the Parties may terminate the Agreement unilaterally with a written notification. In case of termination of the Agreement according to this clause, Customer has to pay the Service Fee for the period they used the Platform. 

d) Non-Assignment 

Customer does not have the right to assign or transfer its rights and obligations arising from this Agreement to any third party, in whole or in part, without the written permission of Provider. Otherwise, Provider may terminate the Agreement at any time, without a need to make any payment. 

e) Notifications 

 Notifications made by Provider to the e-mail address given by the Customer during negotiations is considered valid and unless Provider is informed of any changes that may occur on the e-mail address, the valid notification address shall be this e-mail address. 

f) Resolution of  Disputes 

In all disputes that may arise from this Agreement Laws of Republic of Turkey shall apply. Parties accept that in resolving disputes arising from this Agreement that Istanbul (Caglayan) courts and executive offices are authorized.`,     
        visible: false,
    }
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <Modal
            style={{ top: 20, }}
            bodyStyle={{height:480, overflow:'scroll', marginRight:4, marginLeft:4, }}
            visible={this.props.visible}
            closable={false}
            footer={[
                <Button key="submit" type="primary" onClick={this.props.onClose}>
                    Close
                </Button>,
            ]}
        >
              <Markdown>{this.props.visible ? this.props.data : ""}</Markdown>
        </Modal> );
    }
}
 
export default AgreementModal;