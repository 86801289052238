
var tenant = window.location.hostname.split('.').shift();

module.exports = {
    Tenant  : tenant,
    API_URL: window._env_.API_URL.replace("subdomain", tenant) + "/api/Service",

    REGISTER_URL:  window._env_.REGISTER_URL,

    BASE_API_URL: window._env_.API_URL.replace("subdomain", tenant),
    VERSION:window._env_.VERSION,
    ONPREM:window._env_.ONPREM === "true" ? true : false,
    RECAPTCHA_SITEKEY: "6LfyvcYUAAAAAAOkyZzIMUXqoICqA9fsQZfRw0wt",

    CORE_API_URL: window._env_.CORE_API_URL,
    BACK_OFFICE_API_URL: window._env_.BACK_OFFICE_API_URL,
    DOCUMENTATION: "https://docs.pwfactor.com/en/latest/"
};