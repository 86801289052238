import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'reactstrap';
import { Skeleton } from 'antd';
import { Container } from 'reactstrap';
import Color from './utils/colors';
import Status from './views/StatusPage';
import Register from './views/SuperAdmin/Register/Register';
import Verify from './views/SuperAdmin/Register/Verify';
import Config from './stores/Config';
import UIStore from './stores/UIStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";


if (typeof window !== 'undefined') {

    ReactDOM.render(
        <Container>
            <div style={{ width: '100%', paddingLeft: 10, paddingRight: 10, justifyConent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
                <div style={{ paddingLeft: 10, width: 360 }}>
                    <div style={{ display: 'flex', backgroundColor: Color.WHITE, border: "1px solid " + Color.GREY[200], padding: 20, borderRadius: 4, marginTop: 10 }}><div style={{ width: '100%' }}><Skeleton active paragraph={{ rows: 1 }} /></div></div>
                </div>
                <div style={{ paddingLeft: 10, width: 360 }}>
                    <div style={{ display: 'flex', backgroundColor: Color.WHITE, border: "1px solid " + Color.GREY[200], padding: 20, borderRadius: 4, marginTop: 10 }}><div style={{ width: '100%' }}><Skeleton active paragraph={{ rows: 4 }} /></div></div>
                </div>
            </div>
        </Container>,
        document.getElementById('root'));
    
    if (window.location.hostname === Config.REGISTER_URL) {
        if (window.location.href.includes("Verify")) {
            ReactDOM.render(<Verify />, document.getElementById('root'));
        } else {
            ReactDOM.render(<Register />, document.getElementById('root'));
        }
    }
    else if (Config.Tenant) {
        ReactDOM.render(<div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{" Management Portal"}</title>
                <meta name="description" content={" Management Portal"} />
            </Helmet>
            {/* <Favicon url={site_icon} /> */}
            <App />
        </div>, document.getElementById('root'));

    } else {
        var match = { params: { status: 'notenant' } };
        ReactDOM.render(<Status match={match} />, document.getElementById('root'));
    }

}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
