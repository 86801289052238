import { observable, decorate } from "mobx";
import Language from "./DataSources/Language";
import Service from "./Service";

export class LanguageStore {
    _save = {
        success: false,
        data: null,
        loading: false,
        error: null
    }

    _response = {
        success: false,
        data: null,
        loading: false,
        error: null
    }

    currentLanguage = "en";
    languages = Language;
    items = Language.find(c => c.code === this.currentLanguage).items;


    GETSTRING(key, param) {
        if (key && this.items.find(c => c.key === key.toUpperCase()) && this.items.find(c => c.key === key.toUpperCase()).value !== "") {
            if (param) {
                return this.items.find(c => c.key === key.toUpperCase()).value.replace('{PARAMETER}', param);
            }

            return this.items.find(c => c.key === key.toUpperCase()).value;
        } else {
            return this.currentLanguage + "_" + key;
        }
    }

    setCurrentLanguage(currentLanguage) {
        this.currentLanguage = currentLanguage

        if (this.languages && this.languages.length > 0 && this.languages.find(c => c.code === this.currentLanguage))
        {
            this.items = this.languages.find(c => c.code === this.currentLanguage).items
            return true;
        }

        return false;            
    }

    async fetchLanguage() {
        return new Service().Fetch("/LanguageManagement/GetLanguages", null, false, false)
            .then((response) => {
                if (response.success) {
                    this.languages = JSON.parse(response.data.V);
                    this.setCurrentLanguage(this.currentLanguage);
                }
            })
    }

    async save(contract) {
        this._save.loading = true
        this._save.success = false
        return new Service().Fetch("/LanguageManagement/SaveLanguages", contract)
            .then((response) => {
                this._save = response
            })
    }

}




decorate(LanguageStore, {
    currentLanguage: observable,
    items: observable,
    languages: observable
});

export default new LanguageStore()
