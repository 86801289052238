
export const generateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295) * 16 | 0, v = c === 'x' ? r : (r & 0x3 || r & 0x8);
        return v.toString(16);
    });
}

export const formatContractDates = (contract) => {
    if (contract == null) {
        var beginDate = new Date();
        beginDate.setDate(new Date().getDate() - 7);
        contract = {
            BeginDate: beginDate,
            EndDate: new Date()
        }
    }
    if (contract) {
        if (!contract.BeginDate) {
            contract.BeginDate = new Date();
            contract.BeginDate.setDate(new Date().getDate() - 7);
        }
        if (!contract.EndDate) {
            contract.EndDate = new Date();
        }

        // *   Veritabanındaki 3 saatlik farkdan dolayı;    * //
        // *   BeginDate için 00:00:00 yerine 03:00:00      * //
        // *   EndDate için 23:59:59 yerine 26:59:59        * //
        // *   yazıldı.                                     * //
        contract.BeginDate.setHours(3, 0, 0, 0);
        contract.EndDate.setHours(26, 59, 59, 999);
    }
    return contract;
}

export const renameFile = (startWith) => {
    let today = new Date();
    return (startWith ? (startWith + "_") : "") +
        today.getDate() + "_" +
        (today.getMonth() + 1) + "_" +
        today.getFullYear() + "_" +
        today.getHours() + "_" +
        today.getMinutes() + "_" +
        today.getSeconds() + "_" +
        today.getMilliseconds();
}


export const prepareToRedirectUrl = (subdomain) => {
    var indexOfSubdomain = window.location.host.indexOf('.', 0);
    var domain = window.location.host.substr(indexOfSubdomain, window.location.host.length);
    var completeUrl = window.location.protocol + "//" + subdomain + domain;

    return completeUrl;
}

export const turkishToEnglish = (text) => {
    const turkishChars = 'çÇğĞıİöÖşŞüÜ';
    const englishChars = 'cCgGiIoOsSuU';

    // Türkçe karakterleri İngilizce karakterlere dönüştür
    for (let i = 0; i < turkishChars.length; i++) {
        const regex = new RegExp(turkishChars[i], 'g');
        text = text.replace(regex, englishChars[i]);
    }
    return text;
}