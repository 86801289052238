import React from 'react';
import { notification, Icon, message } from 'antd';
import UserStore from './UserStore';
import Color from '../utils/colors';
import LanguageStore from '../stores/LanguageStore';

export const openNotification = (type, message) => {
    notification.config({
        placement: "topRight",
      });
      
    notification[""+type]({
        message: ""+message,
        style: {
            width: 320,
            marginLeft:'auto',
          }
    });
};

export var isLoadingVisisble = false;

export const openLoading = () => {
    if(!isLoadingVisisble) {
        isLoadingVisisble = message.open({ 
        content: 
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <Icon type="loading" style={{ color: Color.BLUE[600], fontSize: 18 }} /> <span style={{ marginLeft: 10, fontSize: 16 }}>{LanguageStore.GETSTRING('LOADER_TEXT')}</span> </div>, duration: 0 });
    }
};

export const closeLoading = () => {
    setTimeout(() => {
    if (isLoadingVisisble) {
        isLoadingVisisble();
        isLoadingVisisble = false;
    }
    }, 20);
};


export const handlError = (statusCode, message) => {
    closeLoading();
    if (statusCode === 401 || statusCode === 403) {
        UserStore.logout();
        setTimeout(() => {
            if(window.location.pathname !=="/signin")
                    window.location.replace("/signin");
        }, 200);
    }
    else {
        openNotification("error", message);
    }
}