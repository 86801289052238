import React from 'react';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Skeleton } from 'antd';
import LanguageStore from './stores/LanguageStore';
import DeviceConfigurationStore from './stores/DeviceConfigurationStore';

import 'antd/dist/antd.css';
//import GoogleAnalyticsListener from './components/GoogleAnalyticsListener';

const ForgotPasswordLayout = React.lazy(() => import('./views/SuperAdmin/SignIn/ResetPassword'));
const VerifyPinLayout = React.lazy(() => import('./views/SuperAdmin/SignIn/VerifyPin'));
const SuperAdminLayout = React.lazy(() => import('./views/SuperAdmin/SuperAdminLayout'));
const SignIn = React.lazy(() => import('./views/SuperAdmin/SignIn/SignIn'));
const Register = React.lazy(() => import('./views/SuperAdmin/Register/Register'));
const Verify = React.lazy(() => import('./views/SuperAdmin/Register/Verify'));
const StatusPage = React.lazy(() => import('./views/StatusPage'));
const SystemAdminLayout = React.lazy(()=> import('./views/SystemAdmin/Layout/SystemAdminLayout'));
const ChangePasswordForRecovery = React.lazy(() => import('./views/SuperAdmin/SignIn/ChangePassword'));

const loading = () => <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}><div style={{ width: 560 }}><Skeleton avatar paragraph={{ rows: 4 }} /></div></div>;
LanguageStore.fetchLanguage();
DeviceConfigurationStore.fetchDevice();

// <GoogleAnalyticsListener trackingId="UA-157904039-1">

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/forgot-password" name="Forgot Password" render={props => <ForgotPasswordLayout {...props} />} />
              <Route path="/change-password" name="Change Password" render={props => <ChangePasswordForRecovery {...props} />} />
              <Route path="/verify-pin" name="Verify Pin" render={props => <VerifyPinLayout {...props} />} />
              <Route path="/superadmin" name="Super Admin" render={props => <SuperAdminLayout {...props} />} />
              <Route path="/systemadmin" name="System Admin" render={props => <SystemAdminLayout {...props} />} />
              <Route path="/" name="Home" render={props => <SignIn {...props} />} />
              <Route path="/Register" name="Register" render={props => <Register {...props} />} />
              <Route path="/Register/Verify" name="Verify" render={props => <Verify {...props} />} />
              <Route exact path="/status" name="Status Page" render={props => <StatusPage {...props} />} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
