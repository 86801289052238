import { observable, decorate } from "mobx";
import Device from "./DataSources/Device";
import Service from "./Service";

export class DeviceConfigurationStore {
    _save = {
        success: false,
        data: null,
        loading: false,
        error: null
    }

    _response = {
        success: false,
        data: null,
        loading: false,
        error: null
    }

    iosDevice = "IOS";
    devices = Device;
    items = Device.find(c => c.code === this.iosDevice).items;


    GETSTRING(key) {
        if (key && this.items.find(c => c.key === key)) {
            return this.items.find(c => c.key === key).value;
        } else {
            return key;
        }
    }

    setCurrentDevice(iosDevice) {
        this.iosDevice = iosDevice

        if (this.devices && this.devices.length > 0 && this.devices.find(c => c.code === this.iosDevice))
        {
            this.items = this.devices.find(c => c.code === this.iosDevice).items
            return true;
        }

        return false;            
    }

    async fetchDevice() {
        return new Service().Fetch("/DeviceManagement/GetDevices", null, false, false)
            .then((response) => {
                if (response.success) {
                    this.devices = JSON.parse(response.data.V);
                    this.setCurrentDevice(this.iosDevice);
                }
            })
    }

    async save(contract) {
        this._save.loading = true
        this._save.success = false
        return new Service().Fetch("/DeviceManagement/SaveDevices", contract)
            .then((response) => {
                this._save = response
            })
    }

}




decorate(DeviceConfigurationStore, {
    iosDevice: observable,
    items: observable,
    devices: observable
});

export default new DeviceConfigurationStore()
