
const Device = [
  {
    "code": "IOS",
    "title": "IOS Devices",
    "items": [
      {
        "key":  "i386",
        "value":    "IOS i386 simulator"
    },
    {
        "key":  "x86_64",
        "value":    "IOS x86_64 simulator"
    },
    {
        "key":  "iPod1,1",
        "value": "iPod1"
    },
    {
        "key":  "iPod2,1",
        "value": "iPod2"
    },
    {
        "key":  "iPod3,1",
        "value": "iPod3"
    },
    {
        "key":  "iPod4,1",
        "value": "iPod4"
    },
    {
        "key":  "iPod5,1",
        "value": "iPod5"
    },
    {
        "key":  "iPod7,1",
        "value": "iPod6"
    },
    {
        "key":  "iPod9,1",
        "value": "iPod7"
    },
    {
        "key":  "iPad2,1" ,
        "value":    "iPad2"
    },
    {
        "key":  "iPad2,2" ,
        "value":    "iPad2"
    },
    {
        "key":  "iPad2,3" ,
        "value":    "iPad2"
    },
    {
        "key":  "iPad2,4" ,
        "value":    "iPad2"
    },
    {
        "key":  "iPad3,1" ,
        "value":    "iPad3"
    },
    {
        "key":  "iPad3,2" ,
        "value":    "iPad3"
    },
    {
        "key":  "iPad3,3" ,
        "value":    "iPad3"
    },
    {
        "key":  "iPad3,4" ,
        "value":    "iPad4"
    },
    {
        "key":  "iPad3,5" ,
        "value":    "iPad4"
    },
    {
        "key":  "iPad3,6" ,
        "value":    "iPad4"
    },
    {
        "key":  "iPad6,11",
        "value":    "iPad5 - iPad 2017"
    }, 
    {
        "key":  "iPad6,12",
        "value":    "iPad5"
    },
    {
        "key":  "iPad7,5" ,
        "value":    "iPad6 - iPad 2018"
    }, 
    {
        "key":  "iPad7,6" ,
        "value":    "iPad6"
    },
    {
        "key":  "iPad7,11",
        "value":    "iPad7 - iPad 2019"
    }, 
    {
        "key":  "iPad7,12",
        "value":    "iPad7"
    },
    {
        "key":  "iPad11,6",
        "value":    "iPad8 - iPad 2020"
    }, 
    {
        "key":  "iPad11,7",
        "value":    "iPad8"
    },
    {
        "key":  "iPad12,1",
        "value":    "iPad9 - iPad 2021"
    }, 
    {
        "key":  "iPad12,2",
        "value":    "iPad9"
    },
    {
        "key":  "iPad2,5" ,
        "value":    "iPadMini"
    },
    {
        "key":  "iPad2,6" ,
        "value":    "iPadMini"
    },
    {
        "key":  "iPad2,7" ,
        "value":    "iPadMini"
    },
    {
        "key":  "iPad4,4" ,
        "value":    "iPadMini2"
    },
    {
        "key":  "iPad4,5" ,
        "value":    "iPadMini2"
    },
    {
        "key":  "iPad4,6" ,
        "value":    "iPadMini2"
    },
    {
        "key":  "iPad4,7" ,
        "value":    "iPadMini3"
    },
    {
        "key":  "iPad4,8" ,
        "value":    "iPadMini3"
    },
    {
        "key":  "iPad4,9" ,
        "value":    "iPadMini3"
    },
    {
        "key":  "iPad5,1" ,
        "value":    "iPadMini4"
    },
    {
        "key":  "iPad5,2" ,
        "value":    "iPadMini4"
    },
    {
        "key":  "iPad11,1",
        "value":    "iPadMini5"
    },
    {
        "key":  "iPad11,2",
        "value":    "iPadMini5"
    },
    {
        "key":  "iPad14,1",
        "value":    "iPadMini6"
    },
    {
        "key":  "iPad14,2",
        "value":    "iPadMini6"
    },
    {
        "key":  "iPad6,3"  ,
        "value": "iPadPro9_7"
    },
    {
        "key":  "iPad6,4"  ,
        "value": "iPadPro9_7"
    },
    {
        "key":  "iPad7,3"  ,
        "value": "iPadPro10_5"
    },
    {
        "key":  "iPad7,4"  ,
        "value": "iPadPro10_5"
    },
    {
        "key":  "iPad6,7"  ,
        "value": "iPadPro12_9"
    },
    {
        "key":  "iPad6,8"  ,
        "value": "iPadPro12_9"
    },
    {
        "key":  "iPad7,1"  ,
        "value": "iPadPro2_12_9"
    },
    {
        "key":  "iPad7,2"  ,
        "value": "iPadPro2_12_9"
    },
    {
        "key":  "iPad8,1"  ,
        "value": "iPadPro11"
    },
    {
        "key":  "iPad8,2"  ,
        "value": "iPadPro11"
    },
    {
        "key":  "iPad8,3"  ,
        "value": "iPadPro11"
    },
    {
        "key":  "iPad8,4"  ,
        "value": "iPadPro11"
    },
    {
        "key":  "iPad8,9"  ,
        "value": "iPadPro2_11"
    },
    {
        "key":  "iPad8,10" ,
        "value": "iPadPro2_11"
    },
    {
        "key":  "iPad13,4" ,
        "value": "iPadPro3_11"
    },
    {
        "key":  "iPad13,5" ,
        "value": "iPadPro3_11"
    },
    {
        "key":  "iPad13,6" ,
        "value": "iPadPro3_11"
    },
    {
        "key":  "iPad13,7" ,
        "value": "iPadPro3_11"
    },
    {
        "key":  "iPad8,5"  ,
        "value": "iPadPro3_12_9"
    },
    {
        "key":  "iPad8,6"  ,
        "value": "iPadPro3_12_9"
    },
    {
        "key":  "iPad8,7"  ,
        "value": "iPadPro3_12_9"
    },
    {
        "key":  "iPad8,8"  ,
        "value": "iPadPro3_12_9"
    },
    {
        "key":  "iPad8,11" ,
        "value": "iPadPro4_12_9"
    },
    {
        "key":  "iPad8,12" ,
        "value": "iPadPro4_12_9"
    },
    {
        "key":  "iPad13,8" ,
        "value": "iPadPro5_12_9"
    },
    {
        "key":  "iPad13,9" ,
        "value": "iPadPro5_12_9"
    },
    {
        "key":  "iPad13,10",
        "value": "iPadPro5_12_9"
    },
    {
        "key":  "iPad13,11",
        "value": "iPadPro5_12_9"
    },
    {
        "key":  "iPad4,1"  ,
        "value":    "iPadAir"
    },{
        "key":  "iPad4,2"  ,
        "value":    "iPadAir"
    },{
        "key":  "iPad4,3"  ,
        "value":    "iPadAir"
    },{
        "key":  "iPad5,3"  ,
        "value":    "iPadAir2"
    },
    {
        "key":  "iPad5,4"  ,
        "value":    "iPadAir2"
    },
    {
        "key":  "iPad11,3" ,
        "value":    "iPadAir3"
    },
    {
        "key":  "iPad11,4" ,
        "value":    "iPadAir3"
    },
    {
        "key":  "iPad13,1" ,
        "value":    "iPadAir4"
    },
    {
        "key":  "iPad13,2" ,
        "value":    "iPadAir4"
    },
    {
        "key":  "iPad13,16",
        "value":    "iPadAir5"
    },
    {
        "key":  "iPad13,17",
        "value":    "iPadAir5"
    },
    {
        "key":  "iPhone3,1",
        "value": "iPhone4"
    },
    {
        "key":  "iPhone3,2" ,
        "value": "iPhone4"
    },
    {
        "key":  "iPhone3,3" ,
        "value": "iPhone4"
    },
    {
        "key":  "iPhone4,1" ,
        "value": "iPhone4S"
    },
    {
        "key":  "iPhone5,1" ,
        "value": "iPhone5"
    },
    {
        "key":  "iPhone5,2" ,
        "value": "iPhone5"
    },
    {
        "key":  "iPhone5,3" ,
        "value": "iPhone5C"
    },
    {
        "key":  "iPhone5,4" ,
        "value": "iPhone5C"
    },
    {
        "key":  "iPhone6,1" ,
        "value": "iPhone5S"
    },
    {
        "key":  "iPhone6,2" ,
        "value": "iPhone5S"
    },
    {
        "key":  "iPhone7,1" ,
        "value": "iPhone6Plus"
    },
    {
        "key":  "iPhone7,2" ,
        "value": "iPhone6"
    },
    {
        "key":  "iPhone8,1" ,
        "value": "iPhone6S"
    },
    {
        "key":  "iPhone8,2" ,
        "value": "iPhone6SPlus"
    },
    {
        "key":  "iPhone8,4" ,
        "value": "iPhoneSE"
    },
    {
        "key":  "iPhone9,1" ,
        "value": "iPhone7"
    },
    {
        "key":  "iPhone9,3" ,
        "value": "iPhone7"
    },
    {
        "key":  "iPhone9,2" ,
        "value": "iPhone7Plus"
    },
    {
        "key":  "iPhone9,4" ,
        "value": "iPhone7Plus"
    },
    {
        "key":  "iPhone10,1",
        "value": "iPhone8"
    },
    {
        "key":  "iPhone10,4",
        "value": "iPhone8"
    },
    {
        "key":  "iPhone10,2",
        "value": "iPhone8Plus"
    },
    {
        "key":  "iPhone10,5",
        "value": "iPhone8Plus"
    },
    {
        "key":  "iPhone10,3",
        "value": "iPhoneX"
    },
    {
        "key":  "iPhone10,6",
        "value": "iPhoneX"
    },
    {
        "key":  "iPhone11,2",
        "value": "iPhoneXS"
    },
    {
        "key":  "iPhone11,4",
        "value": "iPhoneXSMax"
    },
    {
        "key":  "iPhone11,6",
        "value": "iPhoneXSMax"
    },
    {
        "key":  "iPhone11,8",
        "value": "iPhoneXR"
    },
    {
        "key":  "iPhone12,1",
        "value": "iPhone11"
    },
    {
        "key":  "iPhone12,3",
        "value": "iPhone11Pro"
    },
    {
        "key":  "iPhone12,5",
        "value": "iPhone11ProMax"
    },
    {
        "key":  "iPhone12,8",
        "value": "iPhoneSE2"
    },
    {
        "key":  "iPhone13,1",
        "value": "iPhone12Mini"
    },
    {
        "key":  "iPhone13,2",
        "value": "iPhone12"
    },
    {
        "key":  "iPhone13,3",
        "value": "iPhone12Pro"
    },
    {
        "key":  "iPhone13,4",
        "value": "iPhone12ProMax"
    },
    {
        "key":  "iPhone14,4",
        "value": "iPhone13Mini"
    },
    {
        "key":  "iPhone14,5",
        "value": "iPhone13"
    },
    {
        "key":  "iPhone14,2",
        "value": "iPhone13Pro"
    },
    {
        "key":  "iPhone14,3",
        "value": "iPhone13ProMax"
    },
    {
        "key":  "iPhone14,6",
        "value": "iPhoneSE3"
    },
    {
        "key":  "iPhone14,7",
        "value": "iPhone14"
    },
    {
        "key":  "iPhone14,8",
        "value": "iPhone14Plus"
    },
    {
        "key":  "iPhone15,2",
        "value": "iPhone14Pro"
    },
    {
        "key":  "iPhone15,3",
        "value": "iPhone14ProMax"
    },
    {
        "key":  "Watch1,1",
        "value": "AppleWatch1"
    },
    
    {
        "key":  "Watch1,2",
        "value": "AppleWatch1"
    },
    
    {
        "key":  "Watch2,6",
        "value": "AppleWatchS1"
    },
    
    {
        "key":  "Watch2,7",
        "value": "AppleWatchS1"
    },
    
    {
        "key":  "Watch2,3",
        "value": "AppleWatchS2"
    },
    
    {
        "key":  "Watch2,4",
        "value": "AppleWatchS2"
    },
    
    {
        "key":  "Watch3,1",
        "value": "AppleWatchS3"
    },
    
    {
        "key":  "Watch3,2",
        "value": "AppleWatchS3"
    },
    
    {
        "key":  "Watch3,3",
        "value": "AppleWatchS3"
    },
    
    {
        "key":  "Watch3,4",
        "value": "AppleWatchS3"
    },
    
    {
        "key":  "Watch4,1",
        "value": "AppleWatchS4"
    },
    
    {
        "key":  "Watch4,2",
        "value": "AppleWatchS4"
    },
    
    {
        "key":  "Watch4,3",
        "value": "AppleWatchS4"
    },
    
    {
        "key":  "Watch4,4",
        "value": "AppleWatchS4"
    },
    
    {
        "key":  "Watch5,1",
        "value": "AppleWatchS5"
    },
    
    {
        "key":  "Watch5,2",
        "value": "AppleWatchS5"
    },
    
    {
        "key":  "Watch5,3",
        "value": "AppleWatchS5"
    },
    
    {
        "key":  "Watch5,4",
        "value": "AppleWatchS5"
    },
    
    {
        "key":  "Watch5,9",
        "value": "AppleWatchSE"
    },
    
    {
        "key":  "Watch5,10",
        "value" : "AppleWatchSE"
    },
    
    {
        "key":  "Watch5,11",
        "value" : "AppleWatchSE"
    },
    
    {
        "key":  "Watch5,12",
        "value" : "AppleWatchSE"
    },
    
    {
        "key":  "Watch6,1",
        "value": "AppleWatchS6"
    },
    
    {
        "key":  "Watch6,2",
        "value": "AppleWatchS6"
    },
    
    {
        "key":  "Watch6,3",
        "value": "AppleWatchS6"
    },
    
    {
        "key":  "Watch6,4",
        "value": "AppleWatchS6"
    },
    
    {
        "key":  "Watch6,6",
        "value": "AppleWatchS7"
    },
    
    {
        "key":  "Watch6,7",
        "value": "AppleWatchS7"
    },
    
    {
        "key":  "Watch6,8",
        "value": "AppleWatchS7"
    },
    
    {
        "key":  "Watch6,9",
        "value": "AppleWatchS7"
    },
    {
        "key":  "AppleTV1,1" ,
        "value": "AppleTV1"
    },
    {
        "key":  "AppleTV2,1" ,
        "value": "AppleTV2"
    },
    {
        "key":  "AppleTV3,1" ,
        "value": "AppleTV3"
    },
    {
        "key":  "AppleTV3,2" ,
        "value": "AppleTV3"
    },
    {
        "key":  "AppleTV5,3" ,
        "value": "AppleTV4"
    },
    {
        "key":  "AppleTV6,2" ,
        "value": "AppleTV_4K"
    },
    {
        "key":  "AppleTV11,1",
        "value" : "AppleTV2_4K"
    }
    ]
  }
]

export default Device