import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Result, Button } from 'antd';
import { Container } from 'reactstrap';

const StatusMap = {
  '403': {
    title: '403',
    subTitle: 'Sorry, you are not authorized to access this page.',
    extra: <Button href="#/" type="primary">Back Home</Button>,
  },
  '404': {
    title: '404',
    subTitle: 'Sorry, the page you visited does not exist.',
    extra: <Button href="#/" type="primary">Back Home</Button>,
  },
  '500': {
    title: '500',
    subTitle: 'Sorry, the server is wrong.',
    extra: <Button href="/" type="primary">Back Home</Button>,
  },
  success: {
    title: 'Successfully Purchased Cloud Server ECS!',
    subTitle:
      'Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait.',
    extra: [
      <Button type="primary" key="console">
        Go Console
        </Button>,
      <Button key="buy">Buy Again</Button>,
    ],
  },
  info: {
    title: 'Your operation has been executed',
    extra: (
      <Button type="primary" key="console">
        Go Console
        </Button>
    ),
  },
  error: {
    title: 'Submission Failed',
    subTitle: 'Please check and modify the following information before resubmitting.',
    extra: [
      <Button type="primary" key="console">
        Go Console
        </Button>,
    ],
  },
  warning: {
    title: 'There are some problems with your operation.',
    extra: (
      <Button type="primary" key="console">
        Go Console
        </Button>
    ),
  },
};

class StatusPage extends Component {

  render() {
    const { match: { params } } = this.props;

    var status = '404';
    if (params.status && StatusMap[params.status]) {
      status = params.status;
    }

    const resultProps = StatusMap[status];

    return (
      <Container style={{backgroundColor:'#ffffff'}}>
        <Result status={status} {...resultProps} />
      </Container>
    );
  }
}

export default StatusPage;