import React from 'react';
import { Container } from 'reactstrap';
import {
    Form,
    Card,
    Layout,
    Input,
    Button,
    Icon,
    Result,
    Alert,
    Row,
    Col,
    Collapse,
    Typography,
    Popover
} from 'antd';
import TenantStore from '../../../stores/TenantStore';
import { observer } from 'mobx-react';
import Color from "../../../utils/colors";
import Config from '../../../stores/Config';
import { Redirect } from 'react-router-dom';
import LanguageStore from '../../../stores/LanguageStore';
import { withCookies } from 'react-cookie';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prepareToRedirectUrl } from '../../../utils/Helpers';
import AgreementModal from '../../../components/AgreementModal';
import LoadingButton from '../../../components/LoadingButton';

const { Content } = Layout;
const { Panel } = Collapse;
const { Text } = Typography;


const Register = observer(
    class Register extends React.Component {
        constructor(props) {
            super(props);

            if (window.location.search && window.location.search.includes("lang=")) {
                let langCode = window.location.search.replace("?lang=", "")

                if (LanguageStore.setCurrentLanguage(langCode)) {
                    this.props.cookies.set('current-language', langCode, { path: '/', domain: '.pwfactor.com' });
                }
            }
            else {
                LanguageStore.setCurrentLanguage(this.props.cookies.get('current-language') ? this.props.cookies.get('current-language') : "en");
            }
        }

        state = {
            confirmDirty: false,
            autoCompleteResult: [],
            isRegistered: false,
            registeredEmail: null,
            errorAlert: null,
            agreementText: null,
            languageKey: LanguageStore.currentLanguage
        };

        changeLanguage = (langCode) => {
            if (LanguageStore.setCurrentLanguage(langCode)) {
                this.props.cookies.set('current-language', langCode, { path: '/', domain: '.pwfactor.com' });
            }
        }

        handleSubmit = e => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.setState({
                        errorAlert: null,
                    });

                    const contract = {
                        Email: values.email,
                        Password: values.password,
                    }

                    TenantStore.startFreeTrial(contract).then(() => {
                        if (TenantStore._startFreeTrial.success) {
                            TenantStore._startFreeTrial.email = contract.email;
                            this.setState({ isRegistered: true, registeredEmail: values.email })
                        } else {
                            this.setState({
                                errorAlert: TenantStore._startFreeTrial.error
                            });
                        }
                    });
                }
            })
        };

        handleConfirmBlur = e => {
            const { value } = e.target;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };

        checkPassword = (value) => {
            var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/;
            if (value != null && value.match(decimal)) {
                return true;
            }
            else {
                return false;
            }
        }

        compareToFirstPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && value !== form.getFieldValue('password')) {
                callback(LanguageStore.GETSTRING('PASSWORD_INCONSISTENT'));
            }
            else if (!this.checkPassword(value)) {
                callback(LanguageStore.GETSTRING('PASSWORD_VERIFY_MESSAGE'));
            }
            else {
                var passwordValue = form.getFieldValue('password');

                form.setFieldsValue({ "password": "" });
                setTimeout(() => {
                    form.setFieldsValue({ "password": passwordValue });
                    callback();
                }, 100);
            }
        };

        validateToNextPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && this.state.confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            var confirmPasswordValue = form.getFieldValue('confirmPassword');
            if (confirmPasswordValue && value !== confirmPasswordValue) {
                callback(LanguageStore.GETSTRING('PASSWORD_INCONSISTENT'));
            }

            form.setFieldsValue({ "confirmPassword": "" });
            setTimeout(() => {
                form.setFieldsValue({ "confirmPassword": confirmPasswordValue });
                callback();
            }, 100);
        };

        handleWebsiteChange = value => {
            let autoCompleteResult;
            if (!value) {
                autoCompleteResult = [];
            } else {
                autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
            }
            this.setState({ autoCompleteResult });
        };

        componentDidMount() {

            this.setState({
                signInUrl: prepareToRedirectUrl("adminconsole")
            })
        }

        hide = () => {
            this.setState({
                visible: false,
            });
        };


        componentDidUpdate = (prevProps, prevState) => {
            if (this.state.languageKey !== LanguageStore.currentLanguage) {
                let allForm = prevProps.form.getFieldsValue();

                this.props.form.resetFields(allForm);
                this.props.form.setFieldsValue(allForm);

                this.setState({
                    languageKey: LanguageStore.currentLanguage
                })
            }
        }

        handleVisibleChange = visible => {
            this.setState({ visible });
        };

        render() {
            const { getFieldDecorator } = this.props.form;
            return Config.ONPREM ? <Redirect to='/admin' /> : this.state.isRegistered ?
                (
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 32 }}>
                            <img alt="pwflogo" width="320" src={require('../../../images/pwflogo.png')} />
                            <Result
                                style={{ width: 352, maxWidth: 352, paddingTop: 10, marginTop: 20 }}
                                icon={<Icon type="check-circle" style={{ color: Color.GREEN[600] }} />}
                                title={LanguageStore.GETSTRING('VERIFICATION_MAIL_SENT')}
                                subTitle={LanguageStore.GETSTRING('VERIFICATION_MAIL_SENT_SUBTITLE', this.state.registeredEmail)}
                            />
                        </div>
                    </Container>
                )
                : (
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Content style={{ maxWidth: 365, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 32 }}>
                            <img alt="pwflogo" width="320" src={require('../../../images/pwflogo.png')} />
                            <span style={{ fontSize: 22, marginTop: 10, fontWeight: 'lighter', color: Color.GREY[800] }}> {LanguageStore.GETSTRING("SIGN_UP")} </span>
                            <Card style={{ marginTop: 10, borderRadius: 4, width: 352 }}>
                                <Row>
                                    <Col span={24}>
                                        <Form onSubmit={this.handleSubmit} style={{ marginTop: 16, }} >
                                            <Form.Item >
                                                {getFieldDecorator('email', {
                                                    rules: [
                                                        {
                                                            type: 'email',
                                                            message: LanguageStore.GETSTRING('EMAIL_VALID_RULE'),
                                                        },
                                                        {
                                                            required: true,
                                                            message: LanguageStore.GETSTRING('EMAIL_RULE'),
                                                        },
                                                    ],
                                                })(<Input size="large" placeholder={LanguageStore.GETSTRING('EMAIL')} />)}
                                            </Form.Item>
                                            <Form.Item hasFeedback>
                                                {getFieldDecorator('password', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: LanguageStore.GETSTRING('PLEASE_INPUT_YOUR_PASSWORD'),
                                                        },
                                                        {
                                                            validator: this.validateToNextPassword,
                                                        },
                                                    ],
                                                })(<Input.Password maxLength={50} size="large" placeholder={LanguageStore.GETSTRING('PASSWORD')} />)}
                                            </Form.Item>
                                            <Form.Item hasFeedback>
                                                {getFieldDecorator('confirmPassword', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: LanguageStore.GETSTRING('CONFIRM_PASSWORD_RULE'),
                                                        },
                                                        {
                                                            validator: this.compareToFirstPassword,
                                                        },
                                                    ],
                                                })(<Input.Password maxLength={50} size="large" placeholder={LanguageStore.GETSTRING('CONFIRM_PASSWORD')} onBlur={this.handleConfirmBlur} />)}
                                            </Form.Item>
                                            <Collapse expandIcon={({ isActive }) => <Icon style={{ paddingBottom: 20, paddingLeft: 12 }} type="right-circle" rotate={isActive ? 90 : 0} />} bordered={false} style={{ display: "unset" }}>
                                                <Panel header={<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}><span style={{ fontSize: 14, color: Color.GREY[700] }}>{LanguageStore.GETSTRING('PWF_FREE_TRIAL_LIMITATIONS')}</span><span style={{ fontSize: 10, color: Color.GREY[500] }}>{LanguageStore.GETSTRING('CLICK_TO_SEE')}</span></div>} key="2_1" style={{ borderBottom: Color.WHITE, margin: "0px 0px 0px 0px" }}>
                                                    <div style={{ padding: "0px 0px 12px 20px", color: Color.GREY[600] }}>
                                                        <ul style={{ fontSize: 11 }}>
                                                            <li>
                                                                {LanguageStore.GETSTRING('2_APPLICATION')}
                                                            </li>
                                                            <li>
                                                                {LanguageStore.GETSTRING('2_TRANSACTION_TYPES')}
                                                            </li>
                                                            <li>
                                                                {LanguageStore.GETSTRING('TOTAL_API_CALLS')}
                                                            </li>
                                                            <li>
                                                                {LanguageStore.GETSTRING('TRIAL_ACCOUNT_END_DAYS')}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: 11, color: Color.GREY[500] }}>
                                                        {LanguageStore.GETSTRING('LIMITATIONS_TO_ORGANIZATIONS')}
                                                        </span>
                                                    </div>
                                                </Panel>
                                            </Collapse>

                                            <Form.Item style={{ marginBottom: 16 }}>
                                                <LoadingButton htmlType="submit" style={{ backgroundColor: Color.Powerfactor.BLUE, borderColor: Color.Powerfactor.BLUE }} text={LanguageStore.GETSTRING("REGISTER_BUTTON_TEXT")} loading={TenantStore._startFreeTrial.loading} />
                                            </Form.Item>

                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", textAlign: 'center', marginTop: 16 }}>

                                                <Text style={{ fontSize: 12 }}>{LanguageStore.GETSTRING('ACCEPT_AGREEMENT').split('|')[0]}                                                    
                                                    <Button type="link" onClick={() => this.setState({ modalVisible: true })} style={{ fontSize: 12, padding: 4, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>{LanguageStore.GETSTRING("TERMS_CONDITIONS")}</Button>
                                                    {LanguageStore.GETSTRING('ACCEPT_AGREEMENT').split('|')[2]}
                                                </Text>
                                            </div>

                                            <div style={{ marginTop: 10 }}>
                                                {
                                                    this.state.errorAlert ? <Alert message={this.state.errorAlert} type="error" showIcon /> : null
                                                }
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                            <Card style={{ width: 352, maxWidth: 352, borderRadius: 4, marginTop: 10, marginBottom: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ cursor: 'pointer', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                        <Popover placement="top" overlayStyle={{ maxWidth: 148 }} content={
                                            <span onClick={this.hide}>{LanguageStore.languages.map((language) => {
                                                return <div style={{ paddingBottom: 4, }}>
                                                    <div className="dropdown-item" style={{ cursor: 'pointer', paddingTop: 6, paddingBottom: 6, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => this.changeLanguage(language.code)}><div>
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            style={{ fontSize: 10, marginRight: 10, }}
                                                            color={LanguageStore.currentLanguage === language.code ? Color.Powerfactor.BLUE : Color.GREY[400]}
                                                        />
                                                        <span>{language.title}</span></div>
                                                    </div>
                                                </div>
                                            })
                                            }</span>
                                        }
                                            title={LanguageStore.GETSTRING("LANGUAGE")}
                                            trigger="click"
                                            visible={this.state.visible}
                                            onVisibleChange={this.handleVisibleChange}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                <Icon type="global"></Icon>
                                            </div>
                                        </Popover>
                                    </div>
                                    <Text style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {LanguageStore.GETSTRING("HAVE_AN_ACCOUNT")}
                                        <Button size="small" type="link" style={{ color: Color.Powerfactor.BLUE }} onClick={() => {
                                            window.location.replace(this.state.signInUrl);
                                        }}>
                                            {LanguageStore.GETSTRING('SIGN_IN')}
                                        </Button>
                                    </Text>
                                </div>
                            </Card>
                            <AgreementModal
                                visible={this.state.modalVisible}
                                onClose={() => this.setState({ modalVisible: false })}
                                data={this.state.modalData}
                            />
                        </Content>
                    </Container >
                );
        }
    })

const WrappedRegister = Form.create({ name: 'register' })(Register);

export default withCookies(WrappedRegister);