import React from "react";
import { Button, Icon } from 'antd';
import Color from "../utils/colors";


class LoadingButton extends React.Component {
    static defaultProps = {
        ...this.props,
        text: "Button",
        loading: false,
        size: "large",
        style: {
            backgroundColor: Color.Powerfactor.BLUE,
            borderColor: Color.Powerfactor.BLUE,
            color: Color.WHITE
        },
        type: "primary"
    }

    render() {

        return (
            this.props.loading ? <Button size={this.props.size} block style={{ backgroundColor: Color.Powerfactor.BLUE, borderColor: Color.Powerfactor.BLUE, color: Color.WHITE, ...this.props.style }} type={this.props.type} >
                <Icon type="loading" />
            </Button> :
                <Button size={this.props.size} block style={{ backgroundColor: Color.Powerfactor.BLUE, borderColor: Color.Powerfactor.BLUE, color: Color.WHITE, ...this.props.style }} onClick={this.props.onClick} type={this.props.type} htmlType={this.props.onClick ? null : "submit"}>
                    {this.props.children ? this.props.children : this.props.text}
                </Button>
        );
    }
}

LoadingButton.propTypes = {
    loading: (props, propName, componentName) => {
        if (!props.loading) {
            return new Error(`One of props 'loading' was not specified in '${componentName}'.`);
        }
    }
}

export default LoadingButton;