import Config from "./Config"
import LanguageStore from "./LanguageStore";
import { handlError, openLoading, closeLoading } from './NotificationStore';

class Service {

  _response = {
    success: false,
    data: null,
    loading: false,
    error: null,
    totalCount: 0
  }

  getErrorMessages(errors) {
    var errorMessage = "";
    if (errors) {
      errors.forEach(err => {
        errorMessage += err.ErrorMessage + "\n";
      });
    }
    return errorMessage;
  }
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  getCsrfToken() {
    return this.getCookie('csrfToken');
  }


  async Fetch(REQUEST_URL, contract = null, showProgress = true, showError = true) {
    var body = contract != null ? JSON.stringify(contract) : null
    var langCode = LanguageStore.currentLanguage;
    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");
    requestHeaders.append("Accept-Language", langCode);

    const requestOptions = {
      method: "POST",
      mode: 'cors',
      cache: 'no-cache',
      headers: requestHeaders,
      credentials: 'include',
      body: body,
    };

    if (showProgress) {
      openLoading()
    }

    return fetch(Config.API_URL + REQUEST_URL, requestOptions)
      .then(res => {
        if (res.status === 200) {
          const responseHeaders = res.headers;
          return res.json().then(data => {
            data.headers = responseHeaders;
            return data;
          });

        } else if (res.status === 401) {
          setTimeout(() => {
            if (window.location.pathname !== "/signin")
              window.location.replace("/signin");
          }, 200);
          return
        }
        return null;
      })
      .then(response => {
        closeLoading()
        this._response.loading = false
        if (response == null) {
          this._response.success = false;
          return this._response;
        }
        this._response.data = response.Value;
        this._response.totalCount = response.TotalCount;
        this._response.headers = response.headers;

        if (response === 1 || (response && response.Results.length <= 0)) {
          this._response.success = true;
          this._response.error = null;
        } else {
          this._response.success = false;

          if (showError) {
            this._response.error = this.getErrorMessages(response.Results);
            handlError(500, this._response.error);
          }
        }

        return this._response;
      }).catch(err => {
        closeLoading()
        if (showError)
          handlError(500, err.message);
        this._response.loading = false
        this._response.error = err.message

        return this._response;
      });
  }
}

export default Service