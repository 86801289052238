import { observable, decorate } from "mobx";
import Service from "./Service";
import SessionStore from 'mobx-session';

class UserStore {

  constructor() {
    SessionStore.initialize({ name: 'powerfactor' });
  }

  _tenantList = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _login = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _verify = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _verifySystemUser = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _currentUser = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _changePassword = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _resetPassword = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _updatePassword = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _systemUserLogin = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _recoverPassword = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  async clean() {
    this._login = {
      success: false,
      loading: false,
      data: null,
      error: null
    }
  }

  async setCSRFToken(csrfResponse) {
    try {
      var csrfToken = csrfResponse.headers.get('X-CSRF-Token');
      this.createCSRFTokenCookie(csrfToken);
    } catch (error) {
      console.error('CSRF token not found', error);
    }
  }

  async createCSRFTokenCookie(csrfToken) {
    const csrfCookieName = 'csrfToken';
    document.cookie = `csrfToken=${csrfToken}; path=/; SameSite=None; Secure`;
  }

  async logout() {
    SessionStore.deleteSession();
    new Service().Fetch("/RemoveCurrentUserFromSession")
    this.deleteCSRFTokenCookie();
    this.clean();
    window.location.replace('/')
  }

  async deleteCSRFTokenCookie() {
    const csrfCookieName = 'csrfToken';
    const pastDate = new Date(0).toUTCString();
    document.cookie = `${csrfCookieName}=; expires=${pastDate}; path=/; SameSite=None; Secure`;
  }

  async login(contract) {
    this._login.loading = true;
    this._login.success = false;
    this.deleteCSRFTokenCookie();

    return new Service().Fetch("/AdminConsoleSignIn", contract, false)
      .then((response) => {
        this._login = response
        SessionStore.saveSession({}); //response.data
      })
  }

  async getMemberTenants(contract) {
    this._tenantList.loading = true;
    this._tenantList.success = false;

    return new Service().Fetch("/Tenant/SelectMemberTenants", contract, false)
      .then((response) => {
        this._tenantList = response
      })
  }

  async verifyPin(contract) {
    this._verify.loading = true;
    this._verify.success = false;

    return new Service().Fetch("/VerifyAdminLoginOtp", contract, false)
      .then((response) => {
        this._verify = response
      })
  }

  async getCurrentUser(contract) {
    this._currentUser.loading = true;
    this._currentUser.success = false;

    return new Service().Fetch("/GetCurrentUserInfoFromSession", contract)
      .then((response) => {
        if (response.success)
          this.setCSRFToken(response);
        this._currentUser = response
      })
  }

  async forgotPassword(contract) {
    this._resetPassword.loading = true;
    this._resetPassword.success = false;

    return new Service().Fetch("/ForgetPassword", contract, false)
      .then((response) => {
        this._resetPassword = response
      })
  }

  async recoverPasswordWithVerifyCode(contract) {
    this._recoverPassword.loading = true;
    this._recoverPassword.success = false;

    return new Service().Fetch("/VerifyPasswordChange", contract, false)
      .then((response) => {
        this._recoverPassword = response
      })
  }

  async updatePassword(contract) {
    this._updatePassword.loading = true
    this._updatePassword.success = false

    return new Service().Fetch("/Application/ChangePassword", contract, false)
      .then((response) => {
        this._updatePassword = response
      })
  }

  async systemUserSignIn(contract) {
    this._systemUserLogin.loading = true
    this._systemUserLogin.success = false

    return new Service().Fetch("/SystemUserSignIn", contract, false)
      .then((response) => {
        this._systemUserLogin = response
      })
  }


  async verifySystemUserPin(contract) {
    this._verifySystemUser.loading = true;
    this._verifySystemUser.success = false;

    return new Service().Fetch("/VerifySystemUserLoginOtp", contract, false)
      .then((response) => {
        this._verifySystemUser = response
      })
  }
}

decorate(UserStore, {
  _login: observable,
  _verify: observable,
  _changePassword: observable,
  _currentUser: observable,
  _resetPassword: observable,
  _updatePassword: observable,
  _systemUserLogin: observable,
  _verifySystemUser: observable,
  _recoverPassword: observable
});

export default new UserStore()