import { observable, decorate } from "mobx";
import Service from "./Service";

class TenantStore {

  _tenant = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _tenantList = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _featureList = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _updateTenantCertificate = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _invalidateCache = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _addTenant = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _updateTenant = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _deleteTenant = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _generateKeys = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _startFreeTrial = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _verifyTenant = {
    success: false,
    data: null,
    loading: false,
    error: null
  }

  _tenantFilterList = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  _subscriptionList = {
    success: false,
    data: {},
    loading: false,
    error: null
  }

  async getTenant() {
    this._tenant.loading = true;
    this._tenant.success = false;

    return new Service().Fetch("/Tenant/SelectTenant")
      .then((response) => {
        this._tenant = response
      })
  }

  async getTenantList() {
    this._tenantList.loading = true;
    this._tenantList.success = false;

    return new Service().Fetch("/TenantManagement/SelectAllTenant")
      .then((response) => {
        this._tenantList = response
      })
  }

  async getTenantFilterList(contract) {
    this._tenantFilterList.loading = true;
    this._tenantFilterList.success = false;

    return new Service().Fetch("/TenantManagement/FilterTenantList", contract)
      .then((response) => {
        this._tenantFilterList = response
      })
  }

  async getFeatureList() {
    this._featureList.loading = true;
    this._featureList.success = false;

    return new Service().Fetch("/TenantManagement/SelectAllFeatures")
      .then((response) => {
        this._featureList = response
      })
  }

  async updateTenantCertificate(contract) {
    this._updateTenantCertificate.loading = true;
    this._updateTenantCertificate.success = false;

    return new Service().Fetch("/Tenant/UpdateCertificate", contract)
      .then((response) => {
        this._updateTenantCertificate = response
      })
  }

  async invalidateCache(contract) {
    this._invalidateCache.loading = true;
    this._invalidateCache.success = false;

    return new Service().Fetch("/Tenant/InvalidateCache", contract)
      .then((response) => {
        this._invalidateCache = response
      })
  }


  async addTenant(contract) {
    this._addTenant.loading = true;
    this._addTenant.success = false;

    return new Service().Fetch("/TenantManagement/InsertTenant", contract)
      .then((response) => {
        this._addTenant = response
      })
  }

  async updateTenant(contract) {
    this._updateTenant.loading = true;
    this._updateTenant.success = false;

    return new Service().Fetch("/TenantManagement/UpdateTenant", contract)
      .then((response) => {
        this._updateTenant = response
      })
  }

  async deleteTenant(contract) {
    this._deleteTenant.loading = true;
    this._deleteTenant.success = false;

    return new Service().Fetch("/TenantManagement/DeleteTenant", contract)
      .then((response) => {
        this._deleteTenant = response
      })
  }

  async generateKeys() {
    this._generateKeys.loading = true;
    this._generateKeys.success = false;

    return new Service().Fetch("/TenantManagement/GenerateKeys")
      .then((response) => {
        this._generateKeys = response
      })
  }

  async startFreeTrial(contract) {
    this._startFreeTrial.loading = true;
    this._startFreeTrial.success = false;

    return new Service().Fetch("/TenantManagement/StartFreeTrial", contract)
      .then((response) => {
        this._startFreeTrial = response
      })
  }
  
  async verifyTenant(contract) {
    this._verifyTenant.loading = true;
    this._verifyTenant.success = false;

    return new Service().Fetch("/TenantManagement/VerifyTenant", contract)
      .then((response) => {
        this._verifyTenant = response
      })
  }

  async getSubscriptionList() {
    this._subscriptionList.loading = true;
    this._subscriptionList.success = false;
  
    return new Service().Fetch("/TenantManagement/SubscriptionList")
      .then((response) => {
        this._subscriptionList = response
      })
  }
}

decorate(TenantStore, {
  _tenant: observable,
  _generateKeys: observable,
  _deleteTenant: observable,
  _updateTenant: observable,
  _addTenant: observable,
  _invalidateCache: observable,
  _updateTenantCertificate: observable,
  _featureList: observable,
  _startFreeTrial: observable,
  _verifyTenant: observable,
  _subscriptionList: observable
});

export default new TenantStore()
