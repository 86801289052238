
const Language = [
  {
    "code": "en",
    "title": "English",
    "items": [
      {
        "key": "en_MENU_LDAP_CONFIGURATIONS",
        "value": "Ldap"
      },
      {
        "key": "TITLE_USE_APP_ADMIN",
        "value": "Use App Admin"
      },
      {
        "key": "PWF_PUBLIC_KEY",
        "value": "PWF Public Key"
      },
      {
        "key": "TENANT",
        "value": "Tenant"
      },
      {
        "key": "REQUEST_EXECUTED_SUCCESSFULY",
        "value": "Request executed successfully"
      },
      {
        "key": "PASSWORD_INCONSISTENT",
        "value": "Two passwords that you enter is inconsistent!"
      },
      {
        "key": "PASSWORD_VERIFY_MESSAGE",
        "value": "Use at least 8 characters. The password must contain one uppercase letter, one lowercase letter, one numeral and one special character."
      },
      {
        "key": "CURRENT_PASSWORD",
        "value": "Current Password"
      },
      {
        "key": "TENANT_FEATURES",
        "value": "Features"
      },
      {
        "key": "CONFIRM_PASSWORD",
        "value": "Confirm Password"
      },
      {
        "key": "NEW_PASSWORD",
        "value": "New Password"
      },
      {
        "key": "OLD_PASSWORD",
        "value": "Old Password"
      },
      {
        "key": "CHANGE_PASSWORD",
        "value": "Change Password"
      },
      {
        "key": "TABLE_HEADER_LDAP_USER",
        "value": "Ldap User"
      },
      {
        "key": "TABLE_HEADER_EMAIL",
        "value": "Email"
      },
      {
        "key": "TABLE_HEADER_ROLE",
        "value": "Role"
      },
      {
        "key": "CLIENT_LOG_LIST",
        "value": "Client Log List"
      },
      {
        "key": "DOWNLOAD_CONFIGURATION",
        "value": "Download Configuration"
      },
      {
        "key": "DISABLED",
        "value": "Disabled"
      },
      {
        "key": "ENABLED",
        "value": "Enabled"
      },
      {
        "key": "MENU_SELF_SERVICE_JOURNAL_LIST",
        "value": "Self Service Journal List"
      },
      {
        "key": "NEW_ACTIVATION_OTP",
        "value": "New Activation OTP"
      },
      {
        "key": "ADD",
        "value": "Add"
      },
      {
        "key": "NEW_IP_BLACK",
        "value": "New IP"
      },
      {
        "key": "DAILY_TRAFFIC",
        "value": "Daily Traffic"
      },
      {
        "key": "ACTIVATION_LIST",
        "value": "Activation List"
      },
      {
        "key": "SOFT_OTP_TRANSACTION_LIST",
        "value": "SoftOTP Transaction List"
      },
      {
        "key": "LOGIN_INFO_LIST",
        "value": "Login Info List"
      },
      {
        "key": "APPLY_AND_CLOSE",
        "value": "Apply and Close"
      },
      {
        "key": "FILTERS",
        "value": "Filters"
      },
      {
        "key": "TRANSACTION_DATE",
        "value": "Transaction Date"
      },
      {
        "key": "TITLE_CUSTOM_TIMEOUT",
        "value": "Custom Timeout"
      },
      {
        "key": "TITLE_STATUS",
        "value": "Status"
      },
      {
        "key": "TITLE_ACTIVATION_OTP",
        "value": "Activation OTP"
      },
      {
        "key": "ACTIVATION_OTP_DETAIL",
        "value": "Activation OTP Detail"
      },
      {
        "key": "IP_BLACK_LIST_DETAIL",
        "value": "IP Black List Detail"
      },
      {
        "key": "TITLE_DEVICE_MODEL",
        "value": "Device Model"
      },
      {
        "key": "TITLE_DEVICE_ID",
        "value": "Device ID"
      },
      {
        "key": "DEVICE_DETAIL",
        "value": "Device Detail"
      },
      {
        "key": "BLOCKED",
        "value": "Blocked"
      },
      {
        "key": "DELETE_BY_ADMIN",
        "value": "Delete By Admin"
      },
      {
        "key": "ACTIVE",
        "value": "Active"
      },
      {
        "key": "TABLE_HEADER_DEVICE_STATUS",
        "value": "Device Status"
      },
      {
        "key": "DELETE_BY_CLIENT",
        "value": "Delete By Client"
      },
      {
        "key": "ACTIVATION_DETAIL",
        "value": "Activation Detail"
      },
      {
        "key": "UPDATE",
        "value": "Update"
      },
      {
        "key": "TITLE_IS_ACTIVE",
        "value": "Is Active"
      },
      {
        "key": "TITLE_ALGORITHM",
        "value": "Algorithm"
      },
      {
        "key": "TITLE_OTP_TYPE",
        "value": "OTP Type"
      },
      {
        "key": "SOFT_OTP_CONFIGURATION_DETAIL",
        "value": "Soft OTP Configuration Detail"
      },
      {
        "key": "SOFT_OTP_CONFIGURATION_LIST",
        "value": "Soft OTP Configuration List"
      },
      {
        "key": "TITLE_TRANSACTION_TYPE",
        "value": "Trasaction Type"
      },
      {
        "key": "TITLE_TRANSACTION_KEY",
        "value": "Transaction Key"
      },
      {
        "key": "TITLE_APPLICATION",
        "value": "Application"
      },
      {
        "key": "APPLICATION_AUTHORIZATION_DETAIL",
        "value": "Application Authorization Detail"
      },
      {
        "key": "IOS_SDK",
        "value": "IOS SDK"
      },
      {
        "key": "ANDROID_SDK",
        "value": "Android SDK"
      },
      {
        "key": "DOCUMENTATION",
        "value": "Documentation"
      },
      {
        "key": "BACK_OFFICE_API",
        "value": "Back Office API"
      },
      {
        "key": "CORE_API",
        "value": "Core API"
      },
      {
        "key": "PUBLIC_KEY",
        "value": "Public Key"
      },
      {
        "key": "SMTP_PASS",
        "value": "Smpt Password"
      },
      {
        "key": "SMTP_PASS_RULE",
        "value": "SMTP Şifresini giriniz."
      },
      {
        "key": "TENANT_NAME",
        "value": "Tenant Name"
      },
      {
        "key": "DETAILS_OF",
        "value": "Detail"
      },
      {
        "key": "MENU_MENU_LIST",
        "value": "Menu List"
      },
      {
        "key": "MENU_USER_LIST",
        "value": "User List"
      },
      {
        "key": "TRUSTED_CLIENT_APP_LIST",
        "value": "Trusted Client App"
      },
      {
        "key": "TRUSTED_CUSTOMER_DETAIL",
        "value": "Trusted Customer Detail"
      },
      {
        "key": "CHANGE_CERTIFICATE",
        "value": "Change Certificate"
      },
      {
        "key": "TITLE_CONFIGURATION",
        "value": "Conofiguration"
      },
      {
        "key": "MIN_RESPONSE_TIME",
        "value": "Min Response Time"
      },
      {
        "key": "MAX_RESPONSE_TIME",
        "value": "Max Response Time"
      },
      {
        "key": "APPLICATION_TRANSACTIONS",
        "value": "Application Transactions"
      },
      {
        "key": "TRANSACTION_TYPES",
        "value": "Transaction Types"
      },
      {
        "key": "ONLINE_USER",
        "value": "Online User"
      },
      {
        "key": "DAILY_TRANSACTIONS",
        "value": "Daily Transactions"
      },
      {
        "key": "DAILY_LOGIN",
        "value": "Daily Login"
      },
      {
        "key": "TOTAL_ACTIVATION",
        "value": "Total Activations"
      },
      {
        "key": "MENU_TENANT_LIST",
        "value": "Tenant List"
      },
      {
        "key": "MENU_ADMIN_USER_MANAGEMENT",
        "value": "Admin User Management"
      },
      {
        "key": "MENU_ENTERPRISE_GATEWAY",
        "value": "Enterrprise Gateway"
      },
      {
        "key": "MENU_ENVIRONMENTS",
        "value": "Environments"
      },
      {
        "key": "MENU_CONFIGURATIONS",
        "value": "Configurations"
      },
      {
        "key": "MENU_DASHBOARD",
        "value": "Dashboard"
      },
      {
        "key": "APPLICATION_AUTHORIZATION_LIST",
        "value": "Application Authorization List"
      },
      {
        "key": "TITLE_TC_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "TRUSTED_CUSTOMER_LIST",
        "value": "Trusted Customer List"
      },
      {
        "key": "NO",
        "value": "No"
      },
      {
        "key": "YES",
        "value": "Yes"
      },
      {
        "key": "TRANSACTION_DEFINATION_LIST",
        "value": "Transaction Defination List"
      },
      {
        "key": "INFO",
        "value": "Info"
      },
      {
        "key": "CONFIGURATION_LIST",
        "value": "Configuration List"
      },
      {
        "key": "APPLICATION_FEATURES",
        "value": "Application Features"
      },
      {
        "key": "APPLICATION_LIST",
        "value": "Application List"
      },
      {
        "key": "TABLE_HEADER_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TABLE_HEADER_APPLICATION_SIGNATURE",
        "value": "Application Signature"
      },
      {
        "key": "TABLE_HEADER_APPLICATION_HASH",
        "value": "Application Hash"
      },
      {
        "key": "TABLE_HEADER_NAME",
        "value": "Name"
      },
      {
        "key": "TITLE_UPDATE",
        "value": "Update"
      },
      {
        "key": "TITLE_DELETE",
        "value": "Delete"
      },
      {
        "key": "TITLE_ADD_NEW",
        "value": "Add New"
      },
      {
        "key": "TABLE_ROWSPERPAGE",
        "value": "Rows Per Page:"
      },
      {
        "key": "TABLE_HEADER_CUSTOMER",
        "value": "Customer"
      },
      {
        "key": "TABLE_HEADER_APPLICATION",
        "value": "Application"
      },
      {
        "key": "TABLE_HEADER_DEVICEID",
        "value": "Device Id"
      },
      {
        "key": "TABLE_HEADER_DEVICEMODEL",
        "value": "Device Model"
      },
      {
        "key": "TABLE_HEADER_DEVICEOS",
        "value": "OS"
      },
      {
        "key": "TABLE_HEADER_OSVERSION",
        "value": "Version"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONSTATUS",
        "value": "Activation Status"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONBEGINDATE",
        "value": "Activation Begin Date"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONENDDATE",
        "value": "Activation End Date"
      },
      {
        "key": "ITEM_INFO_MODAL_HEADER",
        "value": "Title"
      },
      {
        "key": "ITEM_INFO_MODAL_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "BUTTON_TOOLTIP_SEARCH",
        "value": "Search"
      },
      {
        "key": "BUTTON_TOOLTIP_EXPORTTOEXCEL",
        "value": "Export To Excel"
      },
      {
        "key": "TABLE_HEADER_CLIENTIP",
        "value": "Client IP"
      },
      {
        "key": "TABLE_HEADER_METHODNAME",
        "value": "Method Name"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE",
        "value": "Error"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE_DETAIL",
        "value": "Detail"
      },
      {
        "key": "TABLE_HEADER_APPLICATIONVERSION",
        "value": "Version"
      },
      {
        "key": "TABLE_HEADER_VERSION",
        "value": "Version"
      },
      {
        "key": "TABLE_HEADER_ERROR_ROOTED",
        "value": "Rooted"
      },
      {
        "key": "TABLE_HEADER_DURATION",
        "value": "Duration"
      },
      {
        "key": "TABLE_HEADER_SESSION_KEY",
        "value": "Session"
      },
      {
        "key": "FILTER_TOOLBAR_CUSTOMERID",
        "value": "Customer Id"
      },
      {
        "key": "FILTER_TOOLBAR_BEGINDATE",
        "value": "Begin Date"
      },
      {
        "key": "FILTER_TOOLBAR_ENDDATE",
        "value": "End Date"
      },
      {
        "key": "BUTTON_FILTER",
        "value": "Filter"
      },
      {
        "key": "TABLE_HEADER_ACTIVATION_STATUS",
        "value": "Status"
      },
      {
        "key": "TABLE_HEADER_BEGIN_DATE",
        "value": "Begin Date"
      },
      {
        "key": "TABLE_HEADER_END_DATE",
        "value": "End Date"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_NAME",
        "value": "Transaction Name"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_TYPE",
        "value": "Type"
      },
      {
        "key": "TABLE_HEADER_APPROVEMENT_TYPE",
        "value": "Approve Type"
      },
      {
        "key": "TABLE_HEADER_STATUS",
        "value": "Status"
      },
      {
        "key": "TABLE_HEADER_IPADDRESS",
        "value": "IP Address"
      },
      {
        "key": "TABLE_HEADER_LOGIN_BEGIN",
        "value": "Login Begin"
      },
      {
        "key": "TABLE_HEADER_LOGIN_END",
        "value": "Login End"
      },
      {
        "key": "TABLE_HEADER_LAST_TRASACTION_TIME",
        "value": "Last Transaction"
      },
      {
        "key": "TABLE_HEADER_ACTIVE",
        "value": "Active"
      },
      {
        "key": "TABLE_HEADER_MESSAGE",
        "value": "Message"
      },
      {
        "key": "TABLE_HEADER_MESSAGE_TYPE",
        "value": "Message Type"
      },
      {
        "key": "TABLE_HEADER_MESSAGE_DATE",
        "value": "Message Date"
      },
      {
        "key": "TABLE_HEADER_RETRY_COUNT",
        "value": "Retry Count"
      },
      {
        "key": "TABLE_HEADER_SYSTEM_DATE",
        "value": "System Date"
      },
      {
        "key": "SEND_VERIFICATION_CODE",
        "value": "Send Verification Code"
      },
      {
        "key": "VERIFICATION_CODE",
        "value": "Verification Code"
      },
      {
        "key": "LANGUAGE_MANAGEMENT",
        "value": "Language Management"
      },
      {
        "key": "RESET_PASSWORD_TEXT",
        "value": "Send Password Reset Email"
      },
      {
        "key": "INPUT_PASSWORD_RESET_MAIL",
        "value": "Enter your email address and we will send you a pin to sign in."
      },
      {
        "key": "SIGN_IN",
        "value": "Sign In"
      },
      {
        "key": "RESET_PASSWORD",
        "value": "Reset Password"
      },
      {
        "key": "PLEASE_INPUT_YOUR_PASSWORD",
        "value": "Please input Password !"
      },
      {
        "key": "PASSWORD",
        "value": "Password"
      },
      {
        "key": "FORGOT_PASSWORD",
        "value": "Forgot Password"
      },
      {
        "key": "EMAIL_VALID_RULE",
        "value": "The input is not valid E-mail"
      },
      {
        "key": "EMAIL_RULE",
        "value": "Please input your E-mail!"
      },
      {
        "key": "PLEASE_INPUT_YOUR_EMAIL",
        "value": "Please input your E-mail !"
      },
      {
        "key": "INVALID_EMAIL",
        "value": "The input is not valid E-mail"
      },
      {
        "key": "EMAIL",
        "value": "Email"
      },
      {
        "key": "LANGUAGE",
        "value": "Language"
      },
      {
        "key": "LANG",
        "value": "English"
      },
      {
        "key": "LANGUAGE_CONF_SAVED_SUCCESSFULY",
        "value": "Language changes saved."
      },
      {
        "key": "ADD_LANGUAGE",
        "value": "Add Language"
      },
      {
        "key": "ADD_KEY",
        "value": "Add Key"
      },
      {
        "key": "MENU_LANGUAGE_MANAGEMENT",
        "value": "Language Management"
      },
      {
        "key": "MENU_TENANT",
        "value": "Tenant"
      },
      {
        "key": "MENU_ACTIVATION_OTP",
        "value": "Activation OTP"
      },
      {
        "key": "MENU_BACK_OFFICE_JOURNAL_LIST",
        "value": "Back Office Journal List"
      },
      {
        "key": "MENU_INTEGRATION_JOURNAL",
        "value": "Integration Journal List"
      },
      {
        "key": "MENU_JOURNAL_LIST",
        "value": "Journal List"
      },
      {
        "key": "MENU_IP_BLACK_LIST",
        "value": "IP Black List"
      },
      {
        "key": "MENU_FRAUD_DETECTION_LIST",
        "value": "Fraud Detection"
      },
      {
        "key": "MENU_MENU_MANAGEMENT",
        "value": "Menu Management"
      },
      {
        "key": "MENU_USER_MANAGEMENT",
        "value": "User Management"
      },
      {
        "key": "MENU_CONSOLE",
        "value": "Console"
      },
      {
        "key": "MENU_SOFT_OTP_TRANSACTION_LIST",
        "value": "SoftOTP Transaction List"
      },
      {
        "key": "MENU_QR_TRANSACTION_LIST",
        "value": "QR Transaction List"
      },
      {
        "key": "MENU_TOTAL_COUNTS",
        "value": "Total Counts Report"
      },
      {
        "key": "MENU_LOGIN_INFO_LIST",
        "value": "Login Info List"
      },
      {
        "key": "MENU_TRANSACTION_LIST",
        "value": "Transaction List"
      },
      {
        "key": "MENU_ACTIVATION_LIST",
        "value": "Activation List"
      },
      {
        "key": "MENU_SOFT_OTP_CONFIGURATION",
        "value": "Soft OTP Configration"
      },
      {
        "key": "MENU_APPLICATION_AUTHORIZATION",
        "value": "Application Authorization"
      },
      {
        "key": "MENU_TRANSACTION_DEFINITION",
        "value": "Transaction Definition"
      },
      {
        "key": "MENU_SUMMARY",
        "value": "Summary"
      },
      {
        "key": "MENU_CLIENT_LOG_LIST",
        "value": "Client Log List"
      },
      {
        "key": "MENU_DEVICE_LIST",
        "value": "Device List"
      },
      {
        "key": "MENU_TRUSTED_CLIENT_APP",
        "value": "Trusted Client App"
      },
      {
        "key": "MENU_TRUSTED_CUSTOMER",
        "value": "Trusted Customer"
      },
      {
        "key": "MENU_CONFIGURATION",
        "value": "Configuration"
      },
      {
        "key": "MENU_APPLICATION",
        "value": "Application"
      },
      {
        "key": "MENU_LOGS",
        "value": "Logs"
      },
      {
        "key": "CONFIGURATIONS_CHANGED",
        "value": "Configuration Changed"
      },
      {
        "key": "SAVE_CHANGES",
        "value": "SAVE"
      },
      {
        "key": "MENU_FRAUD",
        "value": "Fraud"
      },
      {
        "key": "MENU_REPORTS",
        "value": "Reports"
      },
      {
        "key": "MENU_APPLICATION_SETUP",
        "value": "Application Setup"
      },
      {
        "key": "MENU_HOME",
        "value": "Home"
      },
      {
        "key": "LOADER_TEXT",
        "value": "Action in progress ..."
      },
      {
        "key": "TITLE_CONFIGURATION_NAME",
        "value": "Name"
      },
      {
        "key": "TITLE_APPROVED_TIMEOUT",
        "value": "Approve Timeout"
      },
      {
        "key": "TITLE_SESSION_TIMEOUT",
        "value": "Session Timeout"
      },
      {
        "key": "TITLE_TIMESTAMP_SESSION_TIMEOUT",
        "value": "T-Timeout"
      },
      {
        "key": "TITLE_SMS_OTP_SESSION_TIMEOUT",
        "value": "Sms OTP Timeout"
      },
      {
        "key": "TITLE_ERROR_LOG_ENABLE",
        "value": "Log Enable"
      },
      {
        "key": "TITLE_IP_BLACK_LIST_RETRY_COUNT",
        "value": "IP Retry Count"
      },
      {
        "key": "TITLE_IP_BLACK_LIST_CHECK_ENABLE",
        "value": "IP Check"
      },
      {
        "key": "TITLE_TRANSACTION_IP_CHECK_ENABLE",
        "value": "T-IP Check"
      },
      {
        "key": "TITLE_FRAUD_DETECTION_CHECK_ENABLE",
        "value": "Fraud Check"
      },
      {
        "key": "TITLE_MESSAGE_LOG_CHECK_ENABLE",
        "value": "Log Check"
      },
      {
        "key": "TITLE_APP_HASH_CHECK_ENABLE",
        "value": "App Hash Check"
      },
      {
        "key": "TITLE_PIN_RETRY_COUNT",
        "value": "PIN Retry"
      },
      {
        "key": "TITLE_PIN_RETRY_CHECK_ENABLE",
        "value": "PIN Retry Check"
      },
      {
        "key": "TITLE_BIOMETRY_TIMEOUT",
        "value": "Biometry Timeout"
      },
      {
        "key": "TITLE_BIOMETRY_TIMEOUT_CHECK_ENABLE",
        "value": "Biometry Timeout Check"
      },
      {
        "key": "TITLE_ACTIVE_LOGIN_CHECK_ENABLE",
        "value": "Active Login Check"
      },
      {
        "key": "TITLE_GET_TRANSACTION_PER_MILLISECOND",
        "value": "T-Per Millisecond"
      },
      {
        "key": "TITLE_GET_TRANSACTION_TIME",
        "value": "Transaction Time"
      },
      {
        "key": "TITLE_UPDATE_CONFIGURATION",
        "value": "Update Configuration"
      },
      {
        "key": "TABLE_HEADER_TRUSTED_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TABLE_HEADER_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "TITLE_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TITLE_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "TITLE_DEVELOPER",
        "value": "Developer"
      },
      {
        "key": "TITLE_REFERENCE",
        "value": "Reference"
      },
      {
        "key": "TITLE_DEFINITION_KEY",
        "value": "Definition Key"
      },
      {
        "key": "TABLE_HEADER_IS_ACTIVE",
        "value": "Is Active"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_DEFINITION_KEY",
        "value": "Definition Key"
      },
      {
        "key": "TABLE_HEADER_OTP_TYPE",
        "value": "OTP Type"
      },
      {
        "key": "TABLE_HEADER_ALGORITHM",
        "value": "Algorithm"
      },
      {
        "key": "TABLE_HEADER_DIGITS",
        "value": "Digits"
      },
      {
        "key": "TITLE_PERIOD",
        "value": "Period"
      },
      {
        "key": "TITLE_ADD",
        "value": "ADD"
      },
      {
        "key": "TITLE_UPDATE",
        "value": "UPDATE"
      },
      {
        "key": "TITLE_APPLICATION_NAME",
        "value": "Application Name"
      },
      {
        "key": "TABLE_HEADER_COUNTS",
        "value": "Counts"
      },
      {
        "key": "TABLE_HEADER_SOFT_OTP",
        "value": "Soft OTP"
      },
      {
        "key": "TABLE_HEADER_SUSPICION_REASON",
        "value": "Suspicion Reason"
      },
      {
        "key": "TABLE_HEADER_TRAN_DATE",
        "value": "Tran Date"
      },
      {
        "key": "TABLE_HEADER_INTEGRATION_HOST_NAME",
        "value": "Integration Host Name"
      },
      {
        "key": "TABLE_HEADER_USER_NAME",
        "value": "User Name"
      },
      {
        "key": "TABLE_HEADER_CLIENT_IP",
        "value": "Client IP"
      },
      {
        "key": "TABLE_HEADER_TENANT_NAME",
        "value": "Tenant Name"
      },
      {
        "key": "TABLE_HEADER_CONTROLLER_NAME",
        "value": "Controller Name"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE_DETAILS",
        "value": "Error Messages"
      },
      {
        "key": "TABLE_HEADER_HOST_NAME",
        "value": "Host Name"
      },
      {
        "key": "TABLE_HEADER_ACTIVATION_OTP",
        "value": "Activation OTP"
      },
      {
        "key": "TABLE_HEADER_CUSTOM_TIMEOUT",
        "value": "Custom Timeout"
      },
      {
        "key": "TABLE_HEADER_GENERATION_DATE",
        "value": "Generation Date"
      },
      {
        "key": "TABLE_HEADER_CREATOR_ADMIN_USER_NAME",
        "value": "Creator User"
      },
      {
        "key": "TITLE_TIME_OUT",
        "value": "Timeout"
      },
      {
        "key": "TABLE_HEADER_SUBDOMAIN",
        "value": "Subdomain"
      },
      {
        "key": "TABLE_HEADER_ACTIONS",
        "value": "Actions"
      },
      {
        "key": "TENANT_MAIL",
        "value": "Mail"
      },
      {
        "key": "PWF_FREE_TRIAL_LIMITATIONS",
        "value": "Powerfactor Free Trial Limitations"
      },
      {
        "key": "2_APPLICATION",
        "value": "2 applications"
      },
      {
        "key": "2_TRANSACTION_TYPES",
        "value": "2 transaction types"
      },
      {
        "key": "TOTAL_API_CALLS",
        "value": "Total of 5 thousand API calls"
      },
      {
        "key": "TRIAL_ACCOUNT_END_DAYS",
        "value": "Evaluation/trial accounts end after 15 days"
      },
      {
        "key": "CLICK_TO_SEE",
        "value": "Click to see"
      },
      {
        "key": "LIMITATIONS_TO_ORGANIZATIONS",
        "value": "*Limitations apply to evaluation organizations only"
      },
      {
        "key": "TERMS_CONDITIONS",
        "value": "Terms & Conditions"
      }
    ]
  },
  {
    "code": "tr",
    "title": "Türkçe",
    "items": [
      {
        "key": "en_MENU_LDAP_CONFIGURATIONS",
        "value": ""
      },
      {
        "key": "TITLE_USE_APP_ADMIN",
        "value": ""
      },
      {
        "key": "PWF_PUBLIC_KEY",
        "value": ""
      },
      {
        "key": "TENANT",
        "value": ""
      },
      {
        "key": "REQUEST_EXECUTED_SUCCESSFULY",
        "value": ""
      },
      {
        "key": "PASSWORD_INCONSISTENT",
        "value": ""
      },
      {
        "key": "PASSWORD_VERIFY_MESSAGE",
        "value": ""
      },
      {
        "key": "CURRENT_PASSWORD",
        "value": ""
      },
      {
        "key": "TENANT_FEATURES",
        "value": ""
      },
      {
        "key": "CONFIRM_PASSWORD",
        "value": ""
      },
      {
        "key": "NEW_PASSWORD",
        "value": ""
      },
      {
        "key": "OLD_PASSWORD",
        "value": ""
      },
      {
        "key": "CHANGE_PASSWORD",
        "value": ""
      },
      {
        "key": "TABLE_HEADER_LDAP_USER",
        "value": ""
      },
      {
        "key": "TABLE_HEADER_EMAIL",
        "value": ""
      },
      {
        "key": "TABLE_HEADER_ROLE",
        "value": ""
      },
      {
        "key": "CLIENT_LOG_LIST",
        "value": ""
      },
      {
        "key": "DOWNLOAD_CONFIGURATION",
        "value": ""
      },
      {
        "key": "DISABLED",
        "value": ""
      },
      {
        "key": "ENABLED",
        "value": ""
      },
      {
        "key": "MENU_SELF_SERVICE_JOURNAL_LIST",
        "value": ""
      },
      {
        "key": "NEW_ACTIVATION_OTP",
        "value": ""
      },
      {
        "key": "ADD",
        "value": ""
      },
      {
        "key": "NEW_IP_BLACK",
        "value": ""
      },
      {
        "key": "DAILY_TRAFFIC",
        "value": ""
      },
      {
        "key": "ACTIVATION_LIST",
        "value": ""
      },
      {
        "key": "SOFT_OTP_TRANSACTION_LIST",
        "value": ""
      },
      {
        "key": "LOGIN_INFO_LIST",
        "value": ""
      },
      {
        "key": "APPLY_AND_CLOSE",
        "value": ""
      },
      {
        "key": "FILTERS",
        "value": ""
      },
      {
        "key": "TRANSACTION_DATE",
        "value": ""
      },
      {
        "key": "TITLE_CUSTOM_TIMEOUT",
        "value": ""
      },
      {
        "key": "TITLE_STATUS",
        "value": ""
      },
      {
        "key": "TITLE_ACTIVATION_OTP",
        "value": ""
      },
      {
        "key": "ACTIVATION_OTP_DETAIL",
        "value": ""
      },
      {
        "key": "IP_BLACK_LIST_DETAIL",
        "value": ""
      },
      {
        "key": "TITLE_DEVICE_MODEL",
        "value": ""
      },
      {
        "key": "TITLE_DEVICE_ID",
        "value": ""
      },
      {
        "key": "DEVICE_DETAIL",
        "value": ""
      },
      {
        "key": "BLOCKED",
        "value": ""
      },
      {
        "key": "DELETE_BY_ADMIN",
        "value": ""
      },
      {
        "key": "ACTIVE",
        "value": ""
      },
      {
        "key": "TABLE_HEADER_DEVICE_STATUS",
        "value": ""
      },
      {
        "key": "DELETE_BY_CLIENT",
        "value": ""
      },
      {
        "key": "ACTIVATION_DETAIL",
        "value": ""
      },
      {
        "key": "UPDATE",
        "value": ""
      },
      {
        "key": "TITLE_IS_ACTIVE",
        "value": ""
      },
      {
        "key": "TITLE_ALGORITHM",
        "value": ""
      },
      {
        "key": "TITLE_OTP_TYPE",
        "value": ""
      },
      {
        "key": "SOFT_OTP_CONFIGURATION_DETAIL",
        "value": ""
      },
      {
        "key": "SOFT_OTP_CONFIGURATION_LIST",
        "value": ""
      },
      {
        "key": "TITLE_TRANSACTION_TYPE",
        "value": ""
      },
      {
        "key": "TITLE_TRANSACTION_KEY",
        "value": ""
      },
      {
        "key": "TITLE_APPLICATION",
        "value": ""
      },
      {
        "key": "APPLICATION_AUTHORIZATION_DETAIL",
        "value": ""
      },
      {
        "key": "IOS_SDK",
        "value": ""
      },
      {
        "key": "ANDROID_SDK",
        "value": ""
      },
      {
        "key": "DOCUMENTATION",
        "value": ""
      },
      {
        "key": "BACK_OFFICE_API",
        "value": ""
      },
      {
        "key": "CORE_API",
        "value": ""
      },
      {
        "key": "PUBLIC_KEY",
        "value": ""
      },
      {
        "key": "TENANT_NAME",
        "value": ""
      },
      {
        "key": "DETAILS_OF",
        "value": ""
      },
      {
        "key": "MENU_MENU_LIST",
        "value": ""
      },
      {
        "key": "MENU_USER_LIST",
        "value": ""
      },
      {
        "key": "TRUSTED_CLIENT_APP_LIST",
        "value": ""
      },
      {
        "key": "TRUSTED_CUSTOMER_DETAIL",
        "value": ""
      },
      {
        "key": "CHANGE_CERTIFICATE",
        "value": ""
      },
      {
        "key": "TITLE_CONFIGURATION",
        "value": ""
      },
      {
        "key": "MIN_RESPONSE_TIME",
        "value": ""
      },
      {
        "key": "MAX_RESPONSE_TIME",
        "value": ""
      },
      {
        "key": "APPLICATION_TRANSACTIONS",
        "value": ""
      },
      {
        "key": "TRANSACTION_TYPES",
        "value": ""
      },
      {
        "key": "ONLINE_USER",
        "value": ""
      },
      {
        "key": "DAILY_TRANSACTIONS",
        "value": ""
      },
      {
        "key": "DAILY_LOGIN",
        "value": ""
      },
      {
        "key": "TOTAL_ACTIVATION",
        "value": ""
      },
      {
        "key": "MENU_TENANT_LIST",
        "value": ""
      },
      {
        "key": "MENU_ADMIN_USER_MANAGEMENT",
        "value": ""
      },
      {
        "key": "MENU_ENTERPRISE_GATEWAY",
        "value": ""
      },
      {
        "key": "MENU_ENVIRONMENTS",
        "value": ""
      },
      {
        "key": "MENU_CONFIGURATIONS",
        "value": ""
      },
      {
        "key": "MENU_DASHBOARD",
        "value": "Pano"
      },
      {
        "key": "APPLICATION_AUTHORIZATION_LIST",
        "value": ""
      },
      {
        "key": "TITLE_TC_DESCRIPTION",
        "value": ""
      },
      {
        "key": "TRUSTED_CUSTOMER_LIST",
        "value": ""
      },
      {
        "key": "NO",
        "value": "Hayır"
      },
      {
        "key": "YES",
        "value": "Evet"
      },
      {
        "key": "TRANSACTION_DEFINATION_LIST",
        "value": "İşlem Tanımlama Listesi"
      },
      {
        "key": "INFO",
        "value": "Bilgiler"
      },
      {
        "key": "CONFIGURATION_LIST",
        "value": "Konfigurasyon Listesi"
      },
      {
        "key": "APPLICATION_FEATURES",
        "value": "Uygulama Özellikleri"
      },
      {
        "key": "APPLICATION_LIST",
        "value": "Uygulama Listesi"
      },
      {
        "key": "TABLE_HEADER_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TABLE_HEADER_APPLICATION_SIGNATURE",
        "value": "Application Signature"
      },
      {
        "key": "TABLE_HEADER_APPLICATION_HASH",
        "value": "Application Hash"
      },
      {
        "key": "TABLE_HEADER_NAME",
        "value": "Name"
      },
      {
        "key": "TITLE_UPDATE",
        "value": "Güncelle"
      },
      {
        "key": "TITLE_DELETE",
        "value": "Sil"
      },
      {
        "key": "TITLE_ADD_NEW",
        "value": "Yeni Kayit"
      },
      {
        "key": "TABLE_ROWSPERPAGE",
        "value": "Sayfa Basina Satir:"
      },
      {
        "key": "TABLE_HEADER_CUSTOMER",
        "value": "Müsteri"
      },
      {
        "key": "TABLE_HEADER_APPLICATION",
        "value": "Uygulama"
      },
      {
        "key": "TABLE_HEADER_DEVICEID",
        "value": "Cihaz ID"
      },
      {
        "key": "TABLE_HEADER_DEVICEMODEL",
        "value": "Cihaz Modeli"
      },
      {
        "key": "TABLE_HEADER_DEVICEOS",
        "value": "OS"
      },
      {
        "key": "TABLE_HEADER_OSVERSION",
        "value": "Versiyon"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONSTATUS",
        "value": "Aktivasyon Durumu"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONBEGINDATE",
        "value": "Aktivasyon Baslangiç Tarihi"
      },
      {
        "key": "TABLE_HEADER_ACTIVATIONENDDATE",
        "value": "Aktivasyon Bitis Tarihi"
      },
      {
        "key": "ITEM_INFO_MODAL_HEADER",
        "value": "Baslik"
      },
      {
        "key": "ITEM_INFO_MODAL_DESCRIPTION",
        "value": "Açiklama"
      },
      {
        "key": "BUTTON_TOOLTIP_SEARCH",
        "value": "Arama"
      },
      {
        "key": "BUTTON_TOOLTIP_EXPORTTOEXCEL",
        "value": "Excel'e Çikar"
      },
      {
        "key": "TABLE_HEADER_CLIENTIP",
        "value": "Client IP"
      },
      {
        "key": "TABLE_HEADER_METHODNAME",
        "value": "Method Name"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE",
        "value": "Hata"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE_DETAIL",
        "value": "Detay"
      },
      {
        "key": "TABLE_HEADER_APPLICATIONVERSION",
        "value": "Versiyon"
      },
      {
        "key": "TABLE_HEADER_VERSION",
        "value": "Versiyon"
      },
      {
        "key": "TABLE_HEADER_ERROR_ROOTED",
        "value": "Rooted"
      },
      {
        "key": "TABLE_HEADER_DURATION",
        "value": "Süre"
      },
      {
        "key": "TABLE_HEADER_SESSION_KEY",
        "value": "Session"
      },
      {
        "key": "FILTER_TOOLBAR_CUSTOMERID",
        "value": "Müsteri Numarasi"
      },
      {
        "key": "FILTER_TOOLBAR_BEGINDATE",
        "value": "Baslangiç Tarihi"
      },
      {
        "key": "FILTER_TOOLBAR_ENDDATE",
        "value": "Bitis Tarihi"
      },
      {
        "key": "BUTTON_FILTER",
        "value": "Filtrele"
      },
      {
        "key": "TABLE_HEADER_ACTIVATION_STATUS",
        "value": "Durum"
      },
      {
        "key": "TABLE_HEADER_BEGIN_DATE",
        "value": "Baslangiç Tarihi"
      },
      {
        "key": "TABLE_HEADER_END_DATE",
        "value": "Bitis Tarihi"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_NAME",
        "value": "Islem Adi"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_TYPE",
        "value": "Tip"
      },
      {
        "key": "TABLE_HEADER_APPROVEMENT_TYPE",
        "value": "Onay Tipi"
      },
      {
        "key": "TABLE_HEADER_STATUS",
        "value": "Durum"
      },
      {
        "key": "TABLE_HEADER_IPADDRESS",
        "value": "IP Adres"
      },
      {
        "key": "TABLE_HEADER_LOGIN_BEGIN",
        "value": "Giris Baslangici"
      },
      {
        "key": "TABLE_HEADER_LOGIN_END",
        "value": "Giris Bitisi"
      },
      {
        "key": "TABLE_HEADER_LAST_TRASACTION_TIME",
        "value": "Son Islem"
      },
      {
        "key": "TABLE_HEADER_ACTIVE",
        "value": "Aktif"
      },
      {
        "key": "TABLE_HEADER_MESSAGE",
        "value": "Mesaj"
      },
      {
        "key": "TABLE_HEADER_MESSAGE_TYPE",
        "value": "Mesaj Tipi"
      },
      {
        "key": "TABLE_HEADER_MESSAGE_DATE",
        "value": "Mesaj Tarihi"
      },
      {
        "key": "TABLE_HEADER_RETRY_COUNT",
        "value": "Tekrar Sayisi"
      },
      {
        "key": "TABLE_HEADER_SYSTEM_DATE",
        "value": "Sistem Tarihi"
      },
      {
        "key": "SEND_VERIFICATION_CODE",
        "value": "Dogrulama Kodu Gönder"
      },
      {
        "key": "VERIFICATION_CODE",
        "value": "Dogrulama Kodu"
      },
      {
        "key": "LANGUAGE_MANAGEMENT",
        "value": "Dil Yönetimi"
      },
      {
        "key": "RESET_PASSWORD_TEXT",
        "value": "Parola Sifirlama Email'ini Gönder"
      },
      {
        "key": "INPUT_PASSWORD_RESET_MAIL",
        "value": "E-posta adresinizi girin, size oturum açmak için bir PIN gönderelim."
      },
      {
        "key": "SIGN_IN",
        "value": "Giris"
      },
      {
        "key": "RESET_PASSWORD",
        "value": "Parola Sifirla"
      },
      {
        "key": "PLEASE_INPUT_YOUR_PASSWORD",
        "value": "Şifre giriniz."
      },
      {
        "key": "PASSWORD",
        "value": "Sifre"
      },
      {
        "key": "FORGOT_PASSWORD",
        "value": "Sifremi Unuttum"
      },
      {
        "key": "EMAIL_VALID_RULE",
        "value": "Geçerli bir mail adresi giriniz"
      },
      {
        "key": "SMTP_PASS",
        "value": "Smpt Şifresi"
      },
      {
        "key": "SMTP_PASS_RULE",
        "value": "SMTP Şifresini giriniz."
      },
      {
        "key": "EMAIL_RULE",
        "value": "Please input your E-mail!"
      },
      {
        "key": "PLEASE_INPUT_YOUR_EMAIL",
        "value": "Lütfen Email adresinizi giriniz !"
      },
      {
        "key": "INVALID_EMAIL",
        "value": "Email adresi geçersiz"
      },
      {
        "key": "EMAIL",
        "value": "E-Posta"
      },
      {
        "key": "LANGUAGE",
        "value": "Dil"
      },
      {
        "key": "LANG",
        "value": "Türkçe"
      },
      {
        "key": "LANGUAGE_CONF_SAVED_SUCCESSFULY",
        "value": "Dil degisiklikleri kaydedildi."
      },
      {
        "key": "ADD_LANGUAGE",
        "value": "Dil Ekle"
      },
      {
        "key": "ADD_KEY",
        "value": "Ekle"
      },
      {
        "key": "MENU_LANGUAGE_MANAGEMENT",
        "value": "Dil Yönetimi"
      },
      {
        "key": "MENU_TENANT",
        "value": "Tenant"
      },
      {
        "key": "MENU_ACTIVATION_OTP",
        "value": "Aktivasyon OTP"
      },
      {
        "key": "MENU_BACK_OFFICE_JOURNAL_LIST",
        "value": "Back Office Journal List"
      },
      {
        "key": "MENU_INTEGRATION_JOURNAL",
        "value": "Integration Journal List"
      },
      {
        "key": "MENU_JOURNAL_LIST",
        "value": "Journal List"
      },
      {
        "key": "MENU_IP_BLACK_LIST",
        "value": "IP Black List"
      },
      {
        "key": "MENU_FRAUD_DETECTION_LIST",
        "value": "Fraud Detection"
      },
      {
        "key": "MENU_MENU_MANAGEMENT",
        "value": "Menü Yönetimi"
      },
      {
        "key": "MENU_USER_MANAGEMENT",
        "value": "Kullanici Yönetimi"
      },
      {
        "key": "MENU_CONSOLE",
        "value": "Konsol"
      },
      {
        "key": "MENU_SOFT_OTP_TRANSACTION_LIST",
        "value": "SoftOTP Islem Listesi"
      },
      {
        "key": "MENU_QR_TRANSACTION_LIST",
        "value": "QR Islem Listesi"
      },
      {
        "key": "MENU_TOTAL_COUNTS",
        "value": "Total Counts Report"
      },
      {
        "key": "MENU_LOGIN_INFO_LIST",
        "value": "Giris Bilgisi Listesi"
      },
      {
        "key": "MENU_TRANSACTION_LIST",
        "value": "Islem Listesi"
      },
      {
        "key": "MENU_ACTIVATION_LIST",
        "value": "Aktivasyon Listesi"
      },
      {
        "key": "MENU_SOFT_OTP_CONFIGURATION",
        "value": "Soft OTP Yapilandirmasi"
      },
      {
        "key": "MENU_APPLICATION_AUTHORIZATION",
        "value": "Uygulama Yetkilendirme"
      },
      {
        "key": "MENU_TRANSACTION_DEFINITION",
        "value": "Islem Tanimi"
      },
      {
        "key": "MENU_SUMMARY",
        "value": "Özet"
      },
      {
        "key": "MENU_CLIENT_LOG_LIST",
        "value": "Client Log List"
      },
      {
        "key": "MENU_DEVICE_LIST",
        "value": "Cihaz Listesi"
      },
      {
        "key": "MENU_TRUSTED_CLIENT_APP",
        "value": "Güvenilir Uygulamalar"
      },
      {
        "key": "MENU_TRUSTED_CUSTOMER",
        "value": "Güvenilir Müsteriler"
      },
      {
        "key": "MENU_CONFIGURATION",
        "value": "Ayarlar"
      },
      {
        "key": "MENU_APPLICATION",
        "value": "Uygulama"
      },
      {
        "key": "MENU_LOGS",
        "value": "Logs"
      },
      {
        "key": "CONFIGURATIONS_CHANGED",
        "value": "Degisiklikler Var"
      },
      {
        "key": "SAVE_CHANGES",
        "value": "KAYDET"
      },
      {
        "key": "MENU_FRAUD",
        "value": "Fraud"
      },
      {
        "key": "MENU_REPORTS",
        "value": "Raporlar"
      },
      {
        "key": "MENU_APPLICATION_SETUP",
        "value": "Uygulama Kurulumu"
      },
      {
        "key": "MENU_HOME",
        "value": "Home"
      },
      {
        "key": "LOADER_TEXT",
        "value": "Action in progress ..."
      },
      {
        "key": "LOADER_TEXT",
        "value": "Action in progress ..."
      },
      {
        "key": "TITLE_CONFIGURATION_NAME",
        "value": "Name"
      },
      {
        "key": "TITLE_APPROVED_TIMEOUT",
        "value": "Approve Timeout"
      },
      {
        "key": "TITLE_SESSION_TIMEOUT",
        "value": "Session Timeout"
      },
      {
        "key": "TITLE_TIMESTAMP_SESSION_TIMEOUT",
        "value": "T-Timeout"
      },
      {
        "key": "TITLE_SMS_OTP_SESSION_TIMEOUT",
        "value": "Sms OTP Timeout"
      },
      {
        "key": "TITLE_ERROR_LOG_ENABLE",
        "value": "Log Enable"
      },
      {
        "key": "TITLE_IP_BLACK_LIST_RETRY_COUNT",
        "value": "IP Retry Count"
      },
      {
        "key": "TITLE_IP_BLACK_LIST_CHECK_ENABLE",
        "value": "IP Check"
      },
      {
        "key": "TITLE_TRANSACTION_IP_CHECK_ENABLE",
        "value": "T-IP Check"
      },
      {
        "key": "TITLE_FRAUD_DETECTION_CHECK_ENABLE",
        "value": "Fraud Check"
      },
      {
        "key": "TITLE_MESSAGE_LOG_CHECK_ENABLE",
        "value": "Log Check"
      },
      {
        "key": "TITLE_APP_HASH_CHECK_ENABLE",
        "value": "App Hash Check"
      },
      {
        "key": "TITLE_PIN_RETRY_COUNT",
        "value": "PIN Retry"
      },
      {
        "key": "TITLE_BIOMETRY_TIMEOUT",
        "value": "Biometry Timeout"
      },
      {
        "key": "TITLE_BIOMETRY_TIMEOUT_CHECK_ENABLE",
        "value": "Biometry Timeout Check"
      },
      {
        "key": "TITLE_PIN_RETRY_CHECK_ENABLE",
        "value": "PIN Retry Check"
      },
      {
        "key": "TITLE_ACTIVE_LOGIN_CHECK_ENABLE",
        "value": "Active Login Check"
      },
      {
        "key": "TITLE_GET_TRANSACTION_PER_MILLISECOND",
        "value": "T-Per Millisecond"
      },
      {
        "key": "TITLE_GET_TRANSACTION_TIME",
        "value": "Transaction Time"
      },
      {
        "key": "TITLE_UPDATE_CONFIGURATION",
        "value": "Update Configuration"
      },
      {
        "key": "TABLE_HEADER_TRUSTED_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TABLE_HEADER_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "TITLE_CUSTOMER_ID",
        "value": "Customer Id"
      },
      {
        "key": "TITLE_DESCRIPTION",
        "value": "Description"
      },
      {
        "key": "TITLE_DEVELOPER",
        "value": "Developer"
      },
      {
        "key": "TITLE_REFERENCE",
        "value": "Reference"
      },
      {
        "key": "TABLE_HEADER_IS_ACTIVE",
        "value": "Is Active"
      },
      {
        "key": "TITLE_DEFINITION_KEY",
        "value": "Definition Key"
      },
      {
        "key": "TABLE_HEADER_IS_ACTIVE",
        "value": "Is Active"
      },
      {
        "key": "TABLE_HEADER_TRANSACTION_DEFINITION_KEY",
        "value": "Definition Key"
      },
      {
        "key": "TABLE_HEADER_OTP_TYPE",
        "value": "OTP Type"
      },
      {
        "key": "TABLE_HEADER_ALGORITHM",
        "value": "Algorithm"
      },
      {
        "key": "TABLE_HEADER_DIGITS",
        "value": "Digits"
      },
      {
        "key": "TITLE_PERIOD",
        "value": "Period"
      },
      {
        "key": "TITLE_ADD",
        "value": "ADD"
      },
      {
        "key": "TITLE_UPDATE",
        "value": "UPDATE"
      },
      {
        "key": "TITLE_APPLICATION_NAME",
        "value": "Application Name"
      },
      {
        "key": "TABLE_HEADER_COUNTS",
        "value": "Counts"
      },
      {
        "key": "TABLE_HEADER_SOFT_OTP",
        "value": "Soft OTP"
      },
      {
        "key": "TABLE_HEADER_SUSPICION_REASON",
        "value": "Suspicion Reason"
      },
      {
        "key": "TABLE_HEADER_TRAN_DATE",
        "value": "Tran Date"
      },
      {
        "key": "TABLE_HEADER_INTEGRATION_HOST_NAME",
        "value": "Integration Host Name"
      },
      {
        "key": "TABLE_HEADER_USER_NAME",
        "value": "User Name"
      },
      {
        "key": "TABLE_HEADER_CLIENT_IP",
        "value": "Client IP"
      },
      {
        "key": "TABLE_HEADER_TENANT_NAME",
        "value": "Tenant Name"
      },
      {
        "key": "TABLE_HEADER_CONTROLLER_NAME",
        "value": "Controller Name"
      },
      {
        "key": "TABLE_HEADER_ERROR_MESSAGE_DETAILS",
        "value": "Error Messages"
      },
      {
        "key": "TABLE_HEADER_HOST_NAME",
        "value": "Host Name"
      },
      {
        "key": "TABLE_HEADER_ACTIVATION_OTP",
        "value": "Activation OTP"
      },
      {
        "key": "TABLE_HEADER_CUSTOM_TIMEOUT",
        "value": "Custom Timeout"
      },
      {
        "key": "TABLE_HEADER_GENERATION_DATE",
        "value": "Generation Date"
      },
      {
        "key": "TABLE_HEADER_CREATOR_ADMIN_USER_NAME",
        "value": "Creator User"
      },
      {
        "key": "TITLE_TIME_OUT",
        "value": "Timeout"
      },
      {
        "key": "TABLE_HEADER_SUBDOMAIN",
        "value": "Subdomain"
      },
      {
        "key": "TABLE_HEADER_ACTIONS",
        "value": "Actions"
      },
      {
        "key": "TENANT_MAIL",
        "value": "Mail"
      },
      {
        "key": "PWF_FREE_TRIAL_LIMITATIONS",
        "value": "PWF Ücretsiz Deneme Sınırlamaları"
      },
      {
        "key": "2_APPLICATION",
        "value": "2 uygulama"
      },
      {
        "key": "2_TRANSACTION_TYPES",
        "value": "2 işlem türü"
      },
      {
        "key": "TOTAL_API_CALLS",
        "value": "Toplam 5 bin API çağrısı"
      },
      {
        "key": "TRIAL_ACCOUNT_END_DAYS",
        "value": "Değerlendirme/deneme hesapları 15 gün sonra sona erer"
      },
      {
        "key": "CLICK_TO_SEE",
        "value": "Görmek için tıklayın"
      },
      {
        "key": "LIMITATIONS_TO_ORGANIZATIONS",
        "value": "*Sınırlamalar yalnızca değerlendirme kuruluşları için geçerlidir"
      },
      {
        "key": "TERMS_CONDITIONS",
        "value": "Şartlar & Koşullar"
      }
    ]
  }
]

export default Language