import { observable, decorate, toJS } from "mobx";
import Service from "./Service";
import LanguageStore from './LanguageStore';


class UIStore {

    _appearance = {
        success: false,
        list: [],
        loading: false,
        error: null
    }

    _tenant = {
        success: false,
        data: null,
        loading: false,
        error: null
    }

      _get = {
        success: false,
        list: [],
        loading: false,
        error: null
    }

    _update = {
        success: false,
        data: null,
        loading: false,
        message: null,
        error: null
    }

    _post = {
        success: false,
        data: null,
        loading: false,
        message: null,
        error: null
    }

    _delete = {
        success: false,
        data: null,
        loading: false,
        message: null,
        error: null
    }


    async get_tenant() {
        this._tenant.loading = true;
        this._tenant.success = false;

        return new Service().Fetch("/PortalConfigurations/GetTenant", null, false, false)
            .then((response) => {
                if(response.success && response.data){
                response.data.isActive = true
                this._tenant = response
                }
                else{
                    this._tenant.success =false;
                }
                return response.data;
            })
    }

    async get_appearance() {
        this._appearance.loading = true;
        this._appearance.success = false;

        var contract = {
            Category: "appearance"
        }

        return new Service().Fetch("/PortalConfigurations/GetUIData", contract, false, false)
            .then((response) => {
                
                this._appearance.success = true;
                this._appearance.loading = false;
                this._appearance.error = null;
                
                var json = []
                response.data.forEach(item => {
                    var temporary = item
                    temporary.data = JSON.parse(item.Data)

                    json.push(temporary)    
                })

                this._appearance.list = json
                
            }).catch((e)=>{
                  this._appearance.list = []
            })
    }


    async get() {
        this._get.loading = true
        this._get.success = false
        var contract = {}

        return new Service().Fetch("/PortalConfigurations/GetUIData", contract, false, true)
            .then((response) => {
                
                this._get.success = true;
                this._get.loading = false;
                this._get.error = null;
             
                var list = response.data.map(item => {
                    var obj = item;
                    obj.Data = JSON.parse(""+item.Data)
                    return obj
                });
                
                this._get.list = toJS(list);
                
            }).catch((e)=>{
                console.log(e);
                  this._get.list = []
            })
    }


    async update(mcontract) {
        this._update.loading = true
        this._update.success = false

        var contract = {
                Category: mcontract.Category,
                Data: JSON.stringify(mcontract.Data),
                Id: mcontract.Id,
                TypeOf: mcontract.TypeOf
        }

        return new Service().Fetch("/PortalConfigurations/UpdateUIData", contract, false, true)
            .then((response) => {
                
                this._update.success = true;
                this._update.loading = false;
                this._update.error = null;
                this._update.message = LanguageStore.GETSTRING("SAVE_SUCCESS");
                
            }).catch((e)=>{
                console.log(e);
            })
    }


    async post(mcontract) {
         this._post.loading = true
        this._post.success = false

        var contract = {
                Category: mcontract.Category,
                Data: JSON.stringify(mcontract.Data),
                TypeOf: mcontract.TypeOf
        }

        return new Service().Fetch("/PortalConfigurations/InsertUIData", contract, false, true)
            .then((response) => {
                if(response.success)
                    this.get();
                this._post = response;
                this._post.message = LanguageStore.GETSTRING("SAVE_SUCCESS");
                
            }).catch((e)=>{
                console.log(e);
            })
    }

    async delete(id) {
        this._delete.loading = true
        this._delete.success = false

        var contract = {
                Id: id,
        }

        return new Service().Fetch("/PortalConfigurations/DeleteUIData", contract, false, true)
            .then((response) => {
                
                if(response.success)
                    this.get();
                this._delete = response;
                this._delete.message = LanguageStore.GETSTRING("SAVE_SUCCESS");
                
            }).catch((e)=>{
                console.log(e);
            })
    }

}

decorate(UIStore, {
    _get: observable,
    _update: observable,
    _post: observable,
    _delete: observable,
    _IntroAdminVisible: observable,
    _appearance: observable,
    _tenant: observable,
});

export default new UIStore()
