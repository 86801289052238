import React, { Component } from 'react';
import Color from "../../../utils/colors"
import 'antd/dist/antd.css';
import { Form,Result,Icon,Button } from 'antd';
import { Container } from 'reactstrap';
import TenantStore from '../../../stores/TenantStore';
import { Redirect } from 'react-router-dom'
import LanguageStore from '../../../stores/LanguageStore';
import { withCookies } from 'react-cookie';


class Verify extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
      icon: "loading",
      title: "Processing...",
      tenant: "",
      color:Color.BLUE[600]
    }
  }
  
   
  componentDidMount() {
    LanguageStore.setCurrentLanguage(this.props.cookies.get('current-language') ? this.props.cookies.get('current-language') : "en");

    var path = window.location.href;
    const query = path.substr(1);
    const result = {};

    query.split('&').forEach(function (part) {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

    var contract = {
      token: Object.values(result)[0]
    }

    TenantStore.verifyTenant(contract).then(() => {
      if (TenantStore._verifyTenant.success) {
        this.setState({
          tenant: TenantStore._verifyTenant.data.Subdomain
        })
        document.title = LanguageStore.GETSTRING('VERIFIED_EMAIL')
        this.setState({ icon: "check-circle", title: LanguageStore.GETSTRING('VERIFIED_EMAIL'), subTitle: LanguageStore.GETSTRING('VERIFIED_EMAIL_SUBTITLE'), color:Color.GREEN[600] });
      }
      else {
        this.setState({ icon: "exclamation-circle", title: TenantStore._verifyTenant.error,color:Color.RED[600] });
      }
    })
  }

  render() {
    
    return this.state.redirect ?
      <Redirect to="/signin" />
      : (
        <Container style={{paddingTop:32, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <img alt="pwflogo" width="320" src={require('../../../images/pwflogo.png')} />
          <Result
            icon={<Icon type={this.state.icon} style={{color:this.state.color}} />}
            title={this.state.title}
            subTitle={this.state.subTitle}
            style={{width:352, maxWidth:352, marginTop:20}}
            extra={
              TenantStore._verifyTenant.success ? 
              <Button type="primary" key="console" onClick={() => window.open(window._env_.BASE_URL.replace("subdomain", this.state.tenant), '_blank')}>
                {LanguageStore.GETSTRING('GET_STARTED')}
              </Button> : null
            }
          />
      </Container>
      );
  }
}

const WrappedVerify = Form.create({ name: 'verify' })(Verify);

export default withCookies(WrappedVerify);